import {AppState} from '../../index';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthSessionState} from './authSession.reducer';

export const authSessionKey = 'authSession';

export const selectAuthSessionState = createFeatureSelector<AppState, AuthSessionState>(authSessionKey);

export const selectAccessToken = createSelector(
    selectAuthSessionState,
    (state: AuthSessionState) => state.token
);
