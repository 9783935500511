/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Training model
 */
export interface Training { 
    /**
     * The unique id of the training
     */
    id?: number;
    /**
     * The week of the training
     */
    week?: number;
    /**
     * The year of the training
     */
    year?: number;
    /**
     * The title of the training
     */
    title?: string;
    /**
     * The url of the training
     */
    url?: string;
}