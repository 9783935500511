import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss'],
})
export class ValidationModalComponent implements OnInit {
  @Input() content: string;
  @Input() shouldFadeOut: boolean;
  public isVisible: boolean;

  constructor() {
    this.isVisible = false;
    this.shouldFadeOut = true;
  }

  ngOnInit() {
    setTimeout(() => {
      this.isVisible = true;
    }, 100);

    if (this.shouldFadeOut) {
      setTimeout(() => {
        this.isVisible = false;
      }, 4000);
    }
  }

}
