import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import { Observable} from 'rxjs';
import {AuthService} from '../../api';
import {Injectable} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import {Resource} from '../../common/repository/resource';
import {Login} from './login';
import {clearAuthSession} from '../../store/reducers/auth/authSession.actions';
import {selectAccessToken, selectAuthSessionState} from '../../store/reducers/auth/authSession.selectors';
import {PostDeviceToken} from './postDeviceToken';
import {switchMap, take} from 'rxjs/operators';
import {AuthSessionState} from '../../store/reducers/auth/authSession.reducer';
import {InvitationCallbackService} from '../../custom-api/invitation-callback.service';
import {PostInvitationCallback} from './postInvitationCallback';
import {Logout} from './logout';
import {PostChangePassword} from './PostChangePassword';
import {PostDeleteAccount} from './PostDeleteAccount';
import {AskForResetPassword} from './askForResetPassword';
import {ResetPasswordCallback} from './resetPasswordCallback';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthSessionRepository {
  constructor(private store: Store<AppState>, private authSessionService: AuthService,
              private translateService: TranslateService,
              private invitationCallbackService: InvitationCallbackService) {}

  login(email: string, password: string): Observable<Resource<boolean>>{
    return this.store.pipe(select(selectAuthSessionState)).pipe(
      take(1),
      switchMap((response: AuthSessionState) => {
        const context = response.context ? response.context : uuidv4();
        const deviceToken = response.firebaseDeviceToken ? response.firebaseDeviceToken : '';
        const login = new Login(this.store, this.authSessionService, email, password, context, deviceToken);
        return login.call();
      })
    );
  }

  postFirebaseDeviceToken(deviceToken: string): Observable<Resource<string>>{

    return this.store.pipe(select(selectAuthSessionState)).pipe(
      take(1),
      switchMap((response: AuthSessionState) => {
        const context = response.context;
        const postDeviceToken = new PostDeviceToken(this.store, this.authSessionService, deviceToken, context);
        return postDeviceToken.call();
      })
    );
  }

  getAccessToken(): Observable<string> {
    return this.store.pipe(select(selectAccessToken));
  }

  clearAuthSession() {
    this.store.dispatch(clearAuthSession());
  }

  postToInvitationCallback(password: string, passwordConfirmation: string, callbackUrl: string){
    const context = uuidv4();
    const postInvitationCallback = new PostInvitationCallback(this.store, this.invitationCallbackService,
      password, passwordConfirmation, callbackUrl, context);
    return postInvitationCallback.call();
  }

  askForResetPassword(email: string) {
    const askForResetPassword = new AskForResetPassword(email, this.authSessionService);
    return askForResetPassword.call();
  }

  resetPasswordCallback(token: string, email: string, password: string, passwordConfirmation: string){
    const context = uuidv4();
    const resetPasswordPost = new ResetPasswordCallback(this.store, this.authSessionService, token, email, password,
      passwordConfirmation, context);
    return resetPasswordPost.call();
  }

  logout(){
    return this.store.pipe(select(selectAuthSessionState)).pipe(
      take(1),
      switchMap((authState: AuthSessionState) => {
        const logoutPost = new Logout(this.store, this.authSessionService, authState.context);
        return logoutPost.call();
      })
    );
  }

  deleteAccount(password: string){
    const postDeleteAccount = new PostDeleteAccount(this.authSessionService, password);
    return postDeleteAccount.call();
  }

  changePassword(oldPassword: string, newPassword: string, newPasswordConfirmation: string){
    const postChangePassword = new PostChangePassword(this.authSessionService, oldPassword, newPassword, newPasswordConfirmation);
    return postChangePassword.call();
  }
}
