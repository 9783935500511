import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthSessionRepository} from '../repositories/auth/authSession.repository';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private authSessionRepository: AuthSessionRepository) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authSessionRepository.getAccessToken().pipe(
      map((token?: string) => {
        if(token == null) {
          return this.router.createUrlTree(['login']);
        }
        return true;
      }));
  }


}
