import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {CustomQuestion, CustomQuestionForm, ManagerService} from '../../api';
import {GetWeeklyQuestion} from './getWeeklyQuestion';
import {Observable} from 'rxjs';
import {Resource} from '../../common/repository/resource';
import {PostWeeklyQuestion} from './postWeeklyQuestion';
import {GetWeeklyResultsWeekYear} from './getWeeklyResultsWeekYear';
import {GetGlobalResults} from './getGlobalResults';
import {
  selectIsSurveyAvailable,
  selectIsWeeklySurveyAvailable,
  selectTeamResultError
} from '../../store/reducers/team-results/team-results.selectors';
import {GetHistoryResults} from './getHistoryResults';
import {SURVEY_TYPE} from '../../types/surveyTypes';

@Injectable({
  providedIn: 'root'
})
export class ManagerRepository {

  constructor(private store: Store<AppState>, private managerService: ManagerService){}

  getWeeklyQuestion(): Observable<Resource<CustomQuestion>>{
    const getWeeklyQuestion = new GetWeeklyQuestion(this.store, this.managerService, SURVEY_TYPE.TEAM_PULSE);
    return getWeeklyQuestion.call();
  }

  postWeeklyQuestion(customQuestionForm: CustomQuestionForm): Observable<Resource<CustomQuestion>>{
    const postWeeklyQuestion = new PostWeeklyQuestion(this.managerService, customQuestionForm, SURVEY_TYPE.TEAM_PULSE);
    return postWeeklyQuestion.call();
  }

  getSafetyWeeklyResults(week: string, year: string){
    const getWeeklyResult = new GetWeeklyResultsWeekYear(this.store, this.managerService, week, year, SURVEY_TYPE.TEAM_PULSE);
    return getWeeklyResult.fetch();
  }

  getTeamWeeklyResults(week: string, year: string){
    const getWeeklyResult = new GetWeeklyResultsWeekYear(this.store, this.managerService, week, year, SURVEY_TYPE.TEAM_PULSE);
    return getWeeklyResult.fetch();
  }

  getSafetyGlobalResults(){
    const getGlobalResults = new GetGlobalResults(this.store, this.managerService, SURVEY_TYPE.SAFETY_PULSE);
    return getGlobalResults.fetch();
  }

  getTeamGlobalResults(){
    const getGlobalResults = new GetGlobalResults(this.store, this.managerService, SURVEY_TYPE.TEAM_PULSE);
    return getGlobalResults.fetch();
  }

  getSafetyHistory(){
    const getHistoryResult = new GetHistoryResults(this.store, this.managerService, SURVEY_TYPE.SAFETY_PULSE);
    return getHistoryResult.fetch();
  }

  getTeamHistory(){
    const getHistoryResult = new GetHistoryResults(this.store, this.managerService, SURVEY_TYPE.TEAM_PULSE);
    return getHistoryResult.fetch();
  }

  getIsSurveyAvailable(): Observable<boolean>{
    return this.store.pipe(select(selectIsSurveyAvailable));
  }

  getIsWeeklySurveyAvailable(): Observable<boolean>{
    return this.store.pipe(select(selectIsWeeklySurveyAvailable));
  }

  getTeamResultError(): Observable<string> {
    return this.store.pipe(select(selectTeamResultError));
  }
}
