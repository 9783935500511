/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Survey question model
 */
export interface Question { 
    /**
     * The unique question id
     */
    id?: number;
    /**
     * The question section
     */
    section?: string;
    /**
     * The question label
     */
    label?: string;
    /**
     * The type of question
     */
    type?: Question.TypeEnum;
    /**
     * The position of the question
     */
    position?: number;
}
export namespace Question {
    export type TypeEnum = 'text' | 'range';
    export const TypeEnum = {
        Text: 'text' as TypeEnum,
        Range: 'range' as TypeEnum
    };
}