import { Component, Input, OnInit } from '@angular/core';
import {QuestionWithAnswers} from '../../api';

@Component({
  selector: 'app-response-range-user',
  templateUrl: './response-range-user.component.html',
  styleUrls: ['./response-range-user.component.scss'],
})
export class ResponseRangeUserComponent implements OnInit {
  @Input() question: QuestionWithAnswers;
  public mouthImageUrl = 'assets/images/sad-mouth.svg';

  constructor() {}

  ngOnInit() {
    const userRate = this.question.user_answer.value.toPrecision(2);

    if (+userRate < 4) {
      this.mouthImageUrl = 'assets/images/sad-mouth.svg';
    } else if (+userRate > 3 && +userRate < 7) {
      this.mouthImageUrl = 'assets/images/normal-mouth.svg';
    } else {
      this.mouthImageUrl = 'assets/images/happy-mouth.svg';
    }
  }
}
