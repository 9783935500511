import {AppState} from '../../index';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserState} from './user.reducer';

export const userKey = 'userState';

export const selectUserState = createFeatureSelector<AppState, UserState>(userKey);

export const selectUserLang = createSelector(
    selectUserState,
    (state: UserState) => state.lang
);

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user
);

export const selectUserRole  = createSelector(
  selectUserState,
  (state: UserState) => state.user.role
);
export const selectNewPulseSafetyAvailable = createSelector(
  selectUserState,
  (state: UserState) => state.haveNotSeenPulseSafety
);
export const selectNewPulseTeamAvailable = createSelector(
  selectUserState,
  (state: UserState) => state.haveNotSeenPulseTeam
);

export const selectIsTrainingAvailable = createSelector(
  selectUserState,
  (state: UserState) => state.haveNotSeenNewTraining
);
export const selectUserTrainingsHistory = createSelector(
  selectUserState,
  (state: UserState) => state.trainingsHistory.slice().sort((a,b) => b.week - a.week)
);
export const selectUserTraining = createSelector(
  selectUserState,
  (state: UserState) => state.training
);
