import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {Survey, SurveyResponse, SurveyService} from '../../api';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {setPulseSafetyAvailable, setPulseTeamAvailable} from '../../store/reducers/user/user.actions';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class GetSurvey extends JustNetworkResource<Survey, SurveyResponse>{
  public type = '';

  constructor(private store: Store, private surveyService: SurveyService, type: SURVEY_TYPE){
    super();
    this.type = type;
  }
  protected startNetworkCall(): Observable<SurveyResponse> {
    return this.surveyService.surveyTypeGet(this.type);
  }

  protected onNetworkSuccess(surveyResponse: SurveyResponse): Survey {
    if (this.type === SURVEY_TYPE.SAFETY_PULSE) {
      this.store.dispatch(setPulseSafetyAvailable({haveNotSeenPulseSafety: true}));
    }
    if (this.type === SURVEY_TYPE.TEAM_PULSE) {
      this.store.dispatch(setPulseTeamAvailable({haveNotSeenPulseTeam: true}));
    }
    return surveyResponse.survey;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    if (this.type === SURVEY_TYPE.SAFETY_PULSE) {
      this.store.dispatch(setPulseSafetyAvailable({haveNotSeenPulseSafety: false}));
    }
    if (this.type === SURVEY_TYPE.TEAM_PULSE) {
      this.store.dispatch(setPulseTeamAvailable({haveNotSeenPulseTeam: false}));
    }
    return UserFriendlyError.genericAsToast( false, error);
  }
}
