import {
  ManagerService,
  TeamGlobalResult,
  TeamGlobalResultResponse
} from '../../api';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {NetworkBoundResource, ResourceFetchOptions} from '../../common/repository/networkBoundResource';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';
import {saveGlobalResults} from '../../store/reducers/team-results/team-results.actions';
import {selectGlobalResults} from '../../store/reducers/team-results/team-results.selectors';
import {HttpErrorResponse} from '@angular/common/http';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class GetGlobalResults extends NetworkBoundResource<any, TeamGlobalResultResponse>{
  public type = '';
  constructor(private store: Store, private managerService: ManagerService, type: SURVEY_TYPE){
    super();
    this.type = type;
  }

  protected loadFromDatabase(): Observable<TeamGlobalResult> {
    return this.store.pipe(select(selectGlobalResults));
  }

  protected saveNetworkResult(dataFromNetwork: TeamGlobalResultResponse) {
    this.store.dispatch(saveGlobalResults({globalResults: dataFromNetwork.global_result, isGlobalAvailable: true}));
    return  dataFromNetwork;
  }

  protected shouldFetch(dataFromDatabase: TeamGlobalResult): ResourceFetchOptions {
      return {
        shouldErrorIfNoNetwork: false,
        shouldFetch: dataFromDatabase == null,
        rateLimiterOptions: {
          maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
          resourceId: this.constructor.name
        }
      };
  }

  protected startNetworkCall(): Observable<TeamGlobalResultResponse> {
    return this.managerService.surveyTypeGlobalResultsGet(this.type);
  }

  protected onNetworkSuccess(dataFromNetwork: TeamGlobalResultResponse): any {
    return super.onNetworkSuccess(dataFromNetwork);
  }

  protected handleNetworkFailure(error: HttpErrorResponse): any {
    if(error.status === 300) {
      this.store.dispatch(saveGlobalResults({globalResults: null, isGlobalAvailable: false}));
    }
    return super.handleNetworkFailure(error.error);
  }
}
