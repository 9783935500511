import { Component, OnInit } from '@angular/core';
import {ResourceStatusService} from '../resource.status.service';
import {UserFriendlyError, UserFriendlyErrorType} from '../../../repository/userFriendlyError';
import {ModalController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ErrorPage} from '../error-page/error.page';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    // private toast;
    private modal;

    private readonly closeString: string;
    private readonly retryString: string;

    constructor(private resourceStatusService: ResourceStatusService,
                private toastController: ToastController,
                private modalController: ModalController,
                private translateService: TranslateService) {
        this.closeString = this.translateService.instant('CLOSE_BUTTON');
        this.retryString = this.translateService.instant('RETRY_BUTTON');
    }

    ngOnInit() {
        this.resourceStatusService.error$.subscribe(async (error: Error) => {
            // if the error is a user friendly error we display it with its configuration
            if (error instanceof UserFriendlyError) {
                const userFriendlyError: UserFriendlyError = error as UserFriendlyError;
                const displayType: UserFriendlyErrorType = userFriendlyError.type;
                switch (displayType) {
                    case UserFriendlyErrorType.TOAST:
                        this.showToast(userFriendlyError);
                        break;
                    case UserFriendlyErrorType.DIALOG:
                        this.showErrorModal(userFriendlyError);
                        break;
                }
            }
        });
    }

    private async showToast(userFriendlyError: UserFriendlyError) {
        const toastOptions = this.configureToast(userFriendlyError);
        // we remove the other toast if still there to not have toast of errors building up.
        // this.handleToastCloseClick();
        const toast = await this.toastController.create(toastOptions);
        await toast.present();
    }

    private configureToast(userFriendlyError: UserFriendlyError) {
        const message = this.translateService.instant(userFriendlyError.messageTransKey);

        let toastOptions = {
            message,
            color: 'danger',
            keyboardClose: true,
            translucent: false
        };

        const toastButtons = [];

        if (userFriendlyError.showClose) {
            toastButtons.push({
                text: this.closeString,
                role: 'cancel'
            });
        }

        if (userFriendlyError.showRetry) {
            toastButtons.push({
                text: this.retryString,
                handler: () => {
                    this.handleToastRetryClick();
                }
            });
        }

        if (toastButtons.length > 0) {
            const extras = { buttons: toastButtons, duration: 12000 };
            toastOptions = {...toastOptions, ...extras};
        }
        else {
            const duration = { duration: 8000 };
            toastOptions = {...toastOptions, ...duration};
        }

        return toastOptions;
    }

    private handleToastRetryClick() {
        this.resourceStatusService.retry.next(true);
    }

    private async showErrorModal(userFriendlyError: UserFriendlyError) {
        const message = this.translateService.instant(userFriendlyError.messageTransKey);

        this.modal = await this.modalController.create({
            component: ErrorPage,
            componentProps: {
                errorMessage: message,
                showClose: userFriendlyError.showClose,
                showRetry: userFriendlyError.showRetry
            }
        });
        await this.modal.present();
    }
}
