import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../api';
import {UserRepository} from '../../repositories/user/user.repository';
import {Resource} from '../../common/repository/resource';
import {Router} from '@angular/router';
import {Status} from '../../common/repository/status';

@Component({
  selector: 'app-safety-pulse',
  templateUrl: './safety-pulse.component.html',
  styleUrls: ['./safety-pulse.component.scss'],
})
export class SafetyPulseComponent implements OnInit {
  public user$: Observable<Resource<User>>;
  constructor(
    private router: Router,
    private userRepository: UserRepository,
  ) {}

  ngOnInit() {
    this.user$ = this.userRepository.getUser();
  }

  cancelSafetyPulse() {
    this.userRepository.cancelSafetyPulse().subscribe( (response) => {
      if (response.status === Status.SUCCESS) {
        location.reload();
      }
    });
  }
}
