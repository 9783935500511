import {Injectable, Optional } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration, InlineResponse200, Token} from '../api';
import {CustomHttpUrlEncodingCodec} from '../api/encoder';


@Injectable()
export class InvitationCallbackService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {}

  /**
   * Accept the user invitation to join the team
   */
  public invitationCallbackPost(callbackURL: string, password: string, password_confirmation: string, context: string, observe?: 'body', reportProgress?: boolean): Observable<Token>;
  public invitationCallbackPost(callbackURL: string, password: string, password_confirmation: string, context: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Token>>;
  public invitationCallbackPost(callbackURL: string, password: string, password_confirmation: string, context: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Token>>;
  public invitationCallbackPost(callbackURL: string, password: string, password_confirmation: string, context: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling postInvitationCallback.');
    }

    if (password_confirmation === null || password_confirmation === undefined) {
      throw new Error('Required parameter password_confirmation was null or undefined when calling postInvitationCallback.');
    }

    if (context === null || context === undefined) {
      throw new Error('Required parameter context was null or undefined when calling postInvitationCallback.');
    }

    if (callbackURL === null || callbackURL === undefined) {
      throw new Error('Required parameter callbackURL was null or undefined when calling postInvitationCallback.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    const useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    formParams = formParams.append('password', <any>password) as any || formParams;

    formParams = formParams.append('password_confirmation', <any>password_confirmation) as any || formParams;

    formParams = formParams.append('context', <any>context) as any || formParams;

    return this.httpClient.request<InlineResponse200>('post',`${callbackURL}`,
      {
        body: formParams,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
