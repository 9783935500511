/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Team weekly question
 */
export interface CustomQuestion { 
    /**
     * The question label
     */
    label?: string;
    /**
     * The type of question
     */
    type?: CustomQuestion.TypeEnum;
    /**
     * The deadline for updating the custom question
     */
    deadline?: string;
}
export namespace CustomQuestion {
    export type TypeEnum = 'text' | 'range';
    export const TypeEnum = {
        Text: 'text' as TypeEnum,
        Range: 'range' as TypeEnum
    };
}