import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {CustomQuestion, CustomQuestionResponse, ManagerService} from '../../api';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class GetWeeklyQuestion extends JustNetworkResource<CustomQuestion, CustomQuestionResponse>{
  public type = '';
  constructor(private store: Store, private managerService: ManagerService, type: SURVEY_TYPE){
    super();
    this.type = type;
  }
  protected startNetworkCall(): Observable<CustomQuestionResponse> {
    return this.managerService.surveyTypeWeeklyQuestionGet(this.type);
  }

  protected onNetworkSuccess(customQuestionResponse: CustomQuestionResponse): CustomQuestion {
    return customQuestionResponse.question;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.genericAsToast( false);
  }

}
