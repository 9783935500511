import {Action, createReducer, on} from '@ngrx/store';
import {Team} from '../../../api';
import * as TeamActions from '../team/team.actions';

export interface TeamState {
  team: Team;
}

export const initialState: TeamState = {
  team: null
};


const userReducer = createReducer(
  initialState,
  on(TeamActions.initTeam, (state, { team }) => ({ ...state, team })),
  on(TeamActions.inviteUser, (state, { team }) => ({ ...state, team })),
  on(TeamActions.deleteUser, (state, { team }) => ({ ...state, team })),
);

export function reducer(state: TeamState, action: Action) {
  return userReducer(state, action);
}
