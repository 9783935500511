import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {InlineResponse200, TermsOfServiceForm, UserService} from '../../api';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class UserAcceptTermsOfServicePost extends JustNetworkResource<string, InlineResponse200>{

  constructor(private userService: UserService, private TermsOfServiceForm: TermsOfServiceForm){
    super();
  }
  protected startNetworkCall(): Observable<InlineResponse200> {
    return this.userService.userAcceptTermsOfServicePost(this.TermsOfServiceForm);
  }

  protected onNetworkSuccess(result: InlineResponse200): string {
    return result.message;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.genericAsToast( false);
  }
}
