import {
  ManagerService, SurveyInstanceHistory,
  TeamGlobalResult, TeamSurveyHistoryResponse
} from '../../api';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {NetworkBoundResource, ResourceFetchOptions} from '../../common/repository/networkBoundResource';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';
import { saveHistory} from '../../store/reducers/team-results/team-results.actions';
import {selectHistory} from '../../store/reducers/team-results/team-results.selectors';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class GetHistoryResults extends NetworkBoundResource<any, TeamSurveyHistoryResponse>{
  public type = '';

  constructor(private store: Store, private managerService: ManagerService, type: SURVEY_TYPE){
    super();
    this.type = type;
  }

  protected loadFromDatabase(): Observable<Array<SurveyInstanceHistory>> {
    return this.store.pipe(select(selectHistory));
  }

  protected saveNetworkResult(dataFromNetwork: TeamSurveyHistoryResponse) {
    this.store.dispatch(saveHistory({history: dataFromNetwork.history }));
    return  dataFromNetwork;
  }

  protected shouldFetch(dataFromDatabase: TeamGlobalResult): ResourceFetchOptions {
      return {
        shouldErrorIfNoNetwork: false,
        shouldFetch: dataFromDatabase == null,
        rateLimiterOptions: {
          maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
          resourceId: this.constructor.name
        }
      };
  }

  protected startNetworkCall(): Observable<TeamSurveyHistoryResponse> {
    return this.managerService.surveyTypeHistoryGet(this.type);
  }

  protected onNetworkSuccess(dataFromNetwork: TeamSurveyHistoryResponse): any {
    return super.onNetworkSuccess(dataFromNetwork);
  }

}
