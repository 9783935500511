import {Component, OnInit} from '@angular/core';
import {AuthSessionRepository} from './repositories/auth/authSession.repository';
import {Router} from '@angular/router';
import firebase from 'firebase/app';
import {environment} from '../environments/environment';
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from '@capacitor/push-notifications';
import {skip} from 'rxjs/operators';
import {Platform} from '@ionic/angular';
import {Observable} from 'rxjs';
import {User} from './api';
import {UserRepository} from './repositories/user/user.repository';
import {BASE_URL} from './app.module';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit{

  public needsToAcceptTOS: boolean;
  public authToken$: Observable<string>;
  public user$: Observable<User>;
  constructor(
    private authSessionRepository: AuthSessionRepository,
    private userRepository: UserRepository,
    private router: Router,
    public platform: Platform
  ) {}

  ngOnInit(): void {
    this.needsToAcceptTOS = true;
    this.authToken$ = this.authSessionRepository.getAccessToken();
    this.user$ = this.userRepository.getCurrentUser();
    this.checkForTokenInvalid();
    this.initFirebase();
    if(!this.platform.platforms().includes('desktop') && !this.platform.platforms().includes('mobileweb') ){
      this.initPushNotificationListener();
    }
  }

  private checkForTokenInvalid() {
    this.authToken$
      .pipe(skip(1)) //We skip the first token to avoid redirecting on accessing invitation
      .subscribe(async (token: string) => {
      if (token === null) {
        await this.router.navigate(['login']);
      }
    });
  }

  private initFirebase(){
    const firebaseConfig = environment.firebaseConfig;

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }

  private initPushNotificationListener(){
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        if(token) {
          console.log('token received : ' + token.value);
          this.authSessionRepository.postFirebaseDeviceToken(token.value).subscribe(() => {
            console.log('Push registration success, token: ' + token.value);
          });
        } else {
          console.log('token is null');
        }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        window.location.reload();
        //alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        window.location.reload();
        //alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
}
