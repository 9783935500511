import {Observable} from 'rxjs';
import {AuthService, InlineResponse200} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class AskForResetPassword extends JustNetworkResource<string, InlineResponse200>{

  constructor(private email: string, private authService: AuthService){
    super();
  }
  protected startNetworkCall(): Observable<InlineResponse200> {
    return this.authService.authForgotPasswordPostForm(this.email);
  }

  protected onNetworkSuccess(response: InlineResponse200): string {
    return response.message;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return this.onOtherNetworkFailure(error);
  }

  protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.displayableAsToast('LOGIN_ASK_RESET_TOAST_ERROR', false);
  }
}
