import {Observable} from 'rxjs';
import {InlineResponse200, UserService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class CancelSafetyPulse extends JustNetworkResource<string, InlineResponse200>{

  constructor(private userService: UserService){
    super();
  }
  protected startNetworkCall(): Observable<InlineResponse200> {
    return this.userService.userCancelSafetyPulsePost();
  }

  protected onNetworkSuccess(response: InlineResponse200): string {
    return response.message;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return this.onOtherNetworkFailure(error);
  }

  protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.displayableAsToast('GENERIC_ERROR', false);
  }
}
