import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button-with-border',
  templateUrl: './button-with-border.component.html',
  styleUrls: ['./button-with-border.component.scss'],
})
export class ButtonWithBorderComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() color = '';
  @Input() isPlain = false;
  @Input() href = '';
  @Input() routerLink: string[] = null;
  @Input() isUppercase = false;
  constructor() { }

  ngOnInit() {}

}
