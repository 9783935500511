/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Current survey instance
 */
export interface CurrentSurveyInstance { 
    /**
     * The id
     */
    id?: number;
    /**
     * The type
     */
    survey_type?: string;
    /**
     * The week
     */
    week?: number;
    /**
     * The year
     */
    year?: number;
}