export * from './auth.service';
import { AuthService } from './auth.service';
export * from './invitations.service';
import { InvitationsService } from './invitations.service';
export * from './manager.service';
import { ManagerService } from './manager.service';
export * from './public.service';
import { PublicService } from './public.service';
export * from './survey.service';
import { SurveyService } from './survey.service';
export * from './tOS.service';
import { TOSService } from './tOS.service';
export * from './team.service';
import { TeamService } from './team.service';
export * from './training.service';
import { TrainingService } from './training.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AuthService, InvitationsService, ManagerService, PublicService, SurveyService, TOSService, TeamService, TrainingService, UserService];
