import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() title: string;
  public isOpen = false;

  constructor() { }

  ngOnInit() {}

  public toggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
