import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {
  CustomQuestion,
  CustomQuestionForm,
  CustomQuestionResponse,
  ManagerService
} from '../../api';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class PostWeeklyQuestion extends JustNetworkResource<CustomQuestion, CustomQuestionResponse>{
  public type = '';
  constructor(private managerService: ManagerService, private customQuestionForm: CustomQuestionForm, type: SURVEY_TYPE){
    super();
    this.type = type;
  }
  protected startNetworkCall(): Observable<CustomQuestionResponse> {
    return this.managerService.surveyTypeWeeklyQuestionPost(this.customQuestionForm, this.type);
  }

  protected onNetworkSuccess(customQuestionResponse: CustomQuestionResponse): CustomQuestion {
    return customQuestionResponse.question;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.genericAsToast( false);
  }
}
