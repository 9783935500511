import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '../../index';
import {TeamResultsState} from './team-results.reducer';


export const teamResultsKey = 'teamResultsState';

export const selectTeamResultsState = createFeatureSelector<AppState, TeamResultsState>(teamResultsKey);

export const selectWeeklyResults = createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.weeklyResults
);

export const selectGlobalResults = createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.globalResults
);

export const selectIsSurveyAvailable= createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.isGlobalAvailable
);
export const selectIsWeeklySurveyAvailable= createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.isWeeklySurveyAvailable
);
export const selectHistory= createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.history
);

export const selectTeamResultError= createSelector(
  selectTeamResultsState,
  (teamState: TeamResultsState) => teamState.weeklyResultsError
);
