import {Observable} from 'rxjs';
import {AuthService, InlineResponse200} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class PostChangePassword extends JustNetworkResource<string, InlineResponse200> {
    constructor(private authSessionService: AuthService, private oldPassword: string,
                private newPassword: string, private newPasswordConfirmation: string) {
        super(true);
    }

    protected startNetworkCall(): Observable<InlineResponse200> {
      return this.authSessionService.authChangePasswordPostForm(
        this.oldPassword, this.newPassword, this.newPasswordConfirmation
        );
    }

    protected onNetworkSuccess(response: InlineResponse200): string {
        return response.message;
    }

    protected  handleNetworkFailure(error: HttpErrorResponse): Error {
      return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
      return UserFriendlyError.displayableAsToast('ACCOUNT_TOAST_PASSWORD_CHANGED_ERROR', false);
    }
}
