import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { Observable, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {switchMap, take, tap} from 'rxjs/operators';
import {ManagerRepository} from '../../repositories/manager/manager.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {Question, SurveyInstanceHistory, TeamSurveyResult} from '../../api';
import {consume} from '../../common/rxjs/operators';
import { map } from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {UserRepository} from '../../repositories/user/user.repository';

@Component({
  selector: 'weekly-results',
  templateUrl: './weekly-results.component.html',
  styleUrls: ['./weekly-results.component.scss'],
})

export class WeeklyResultsComponent implements OnInit, OnDestroy {

  //input
  @Input() weeklyResultsNormalCardTemplate: TemplateRef<any>;
  @Input() weeklyResultsWeatherCardTemplate: TemplateRef<any>;
  errorMessageSubscription: Subscription;

  // output
  public weeklyResult$: Observable<TeamSurveyResult>;
  public history$: Observable<Array<SurveyInstanceHistory>>;
  public isWeeklySurveyAvailable$: Observable<boolean>;
  public errorMessage: string;
  public errorTitle: string;
  public userRole: string;
  public displayedWeek: number;
  public displayedYear: number;
  public currentWeek: number;
  public currentYear: number;
  public slideOptions: any;

  constructor(private managerRepository: ManagerRepository, private datePipe: DatePipe,
              private userRepository: UserRepository,
              private resourceStatusService: ResourceStatusService) {
    this.errorMessageSubscription = Subscription.EMPTY;
    this.errorMessage = null;
    this.errorTitle = null;
    this.userRole = null;
    this.displayedWeek = null;
    this.displayedYear= null;
    this.currentWeek = null;
    this.currentYear = null;
  }

  ngOnInit() {
    this.history$ = this.managerRepository.getTeamHistory().pipe(
      consume(this.resourceStatusService),
      map(history => history.slice().sort((a,b) => {
        const aDate = new Date(a.year.toString());
        aDate.setDate(aDate.getDate() + a.week * 7);
        const bDate = new Date(b.year.toString());
        bDate.setDate(bDate.getDate() + b.week * 7);
        // @ts-ignore
        return aDate - bDate;
      }))
    );
    this.initializeCurrentWeekAndYear();
    this.initializeErrorMessage();
    this.isWeeklySurveyAvailable$ = this.managerRepository.getIsWeeklySurveyAvailable();
    this.slideOptions = {
      initialSlide: Infinity,
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
        350: {
          slidesPerView: 2,
        },
        470: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4
        },
        800: {
          slidesPerView: 5
        },
        1000: {
          slidesPerView: 6
        }
      }
    };
  }

  initializeCurrentWeekAndYear(){
    this.weeklyResult$ = this.history$
      .pipe(
        take(1),
        switchMap((history) => {
          if(history.length>0){
            const lastHistory = history[history.length - 1];
            this.displayedWeek = lastHistory.week;
            this.currentWeek = lastHistory.week;
            this.displayedYear = lastHistory.year;
            return this.managerRepository.getTeamWeeklyResults(lastHistory.week.toString(), lastHistory.year.toString());
          } else{
            return of([]);
          }
        })
      ).pipe(consume(this.resourceStatusService));
  }

  initializeErrorMessage() {
    this.userRepository.getUserRole().subscribe((role) => {
      this.userRole = role;
    });

    this.errorMessageSubscription = this.managerRepository.getTeamResultError()
      .subscribe((message: any ) => {
        for(const key in message){
          if(message.hasOwnProperty(key)){
            if(key === 'survey.not_answered'){
              if(this.displayedWeek && this.currentWeek && this.displayedWeek === this.currentWeek){
                // semaine courante
                this.errorMessage = 'WEEKLY_RESULTS_NO_RESULTS_TEXT_USER_CURRENT_WEEK';
                this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_CURRENT_WEEK';
                break;
              }
              else {
                // semaine passée
                this.errorMessage = this.userRole === 'user' ?
                  'WEEKLY_RESULTS_NO_RESULTS_TEXT_USER_NO_ANSWER_AVAILABLE'
                  :  'WEEKLY_RESULTS_NO_RESULTS_TEXT_MANAGER_NO_ANSWER_AVAILABLE';
                this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_NO_ANSWER_AVAILABLE';
              }
              break;

            }
            else if(key === 'survey.not_enough_answers'){
              if(this.displayedWeek && this.currentWeek && this.displayedWeek === this.currentWeek){
                // semaine courante
                this.errorMessage = 'WEEKLY_RESULTS_NO_RESULTS_TEXT_MANAGER_CURRENT_WEEK';
                this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_CURRENT_WEEK';
              }
              else {
                // semaine passée
                this.errorMessage = 'WEEKLY_RESULTS_NO_RESULTS_TEXT_MANAGER_NO_ANSWER_AVAILABLE';
                this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_NO_ANSWER_AVAILABLE';
              }
              break;
            }
            else if(key === 'survey.not_available'){
              this.errorMessage = this.userRole === 'user' ?
                'WEEKLY_RESULTS_NO_RESULTS_TEXT_USER_NO_ANSWER_AVAILABLE'
                :   'WEEKLY_RESULTS_NO_RESULTS_TEXT_MANAGER_NO_ANSWER_AVAILABLE';
              this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_NO_ANSWER_AVAILABLE';

              break;
            }
            else {
              this.errorMessage = 'GENERIC_ERROR';
              this.errorTitle = 'WEEKLY_RESULTS_NO_RESULTS_TITLE_NO_ANSWER_AVAILABLE';
            }
          }
        }
      });
  }

  public getWeeklyResult(week: number, year: number){
    this.weeklyResult$ = this.managerRepository.getTeamWeeklyResults(week.toString(), year.toString())
      .pipe(consume(this.resourceStatusService),
        tap((res) => {
          this.displayedWeek = week;
          this.displayedYear = year;
          return res;
        }));
  }

  public sortQuestionByPosition(questions?: Array<Question>): Array<Question> {
    const sortedQuestion = questions ? [...questions] : [];
    return sortedQuestion.sort((q1, q2) =>  q1.position - q2.position);
  }

  ngOnDestroy(): void {
    this.errorMessageSubscription.unsubscribe();
  }

  ionViewWillLeave(): void {
    this.errorMessageSubscription.unsubscribe();
  }

}
