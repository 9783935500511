/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeviceTokenUpdateForm } from '../model/deviceTokenUpdateForm';
import { InlineResponse200 } from '../model/inlineResponse200';
import { LogoutForm } from '../model/logoutForm';
import { Token } from '../model/token';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'https://buddy-at-work.mobilethinking.ch';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Change user password
     * 
     * @param old_password 
     * @param password 
     * @param password_confirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authChangePasswordPostForm(old_password: string, password: string, password_confirmation: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authChangePasswordPostForm(old_password: string, password: string, password_confirmation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authChangePasswordPostForm(old_password: string, password: string, password_confirmation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authChangePasswordPostForm(old_password: string, password: string, password_confirmation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (old_password === null || old_password === undefined) {
            throw new Error('Required parameter old_password was null or undefined when calling authChangePasswordPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling authChangePasswordPost.');
        }

        if (password_confirmation === null || password_confirmation === undefined) {
            throw new Error('Required parameter password_confirmation was null or undefined when calling authChangePasswordPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (old_password !== undefined) {
            formParams = formParams.append('old_password', <any>old_password) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (password_confirmation !== undefined) {
            formParams = formParams.append('password_confirmation', <any>password_confirmation) as any || formParams;
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/change_password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete user account
     * 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authDeleteAccountPostForm(password: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authDeleteAccountPostForm(password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authDeleteAccountPostForm(password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authDeleteAccountPostForm(password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling authDeleteAccountPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/delete_account`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the user device token for a given context
     * 
     * @param body Required body parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authDeviceTokenPost(body?: DeviceTokenUpdateForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authDeviceTokenPost(body?: DeviceTokenUpdateForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authDeviceTokenPost(body?: DeviceTokenUpdateForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authDeviceTokenPost(body?: DeviceTokenUpdateForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/device_token`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset user password
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authForgotPasswordPostForm(email: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authForgotPasswordPostForm(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authForgotPasswordPostForm(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authForgotPasswordPostForm(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling authForgotPasswordPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/forgot-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logout the user
     * 
     * @param body Optional body parameters context - if not provided, logout the user from all contexts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authLogoutPost(body?: LogoutForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authLogoutPost(body?: LogoutForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authLogoutPost(body?: LogoutForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authLogoutPost(body?: LogoutForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/logout`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset user password
     * 
     * @param token 
     * @param email 
     * @param context 
     * @param password 
     * @param password_confirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authResetPasswordPostForm(token: string, email: string, context: string, password: string, password_confirmation: string, observe?: 'body', reportProgress?: boolean): Observable<Token>;
    public authResetPasswordPostForm(token: string, email: string, context: string, password: string, password_confirmation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Token>>;
    public authResetPasswordPostForm(token: string, email: string, context: string, password: string, password_confirmation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Token>>;
    public authResetPasswordPostForm(token: string, email: string, context: string, password: string, password_confirmation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling authResetPasswordPost.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling authResetPasswordPost.');
        }

        if (context === null || context === undefined) {
            throw new Error('Required parameter context was null or undefined when calling authResetPasswordPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling authResetPasswordPost.');
        }

        if (password_confirmation === null || password_confirmation === undefined) {
            throw new Error('Required parameter password_confirmation was null or undefined when calling authResetPasswordPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (context !== undefined) {
            formParams = formParams.append('context', <any>context) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (password_confirmation !== undefined) {
            formParams = formParams.append('password_confirmation', <any>password_confirmation) as any || formParams;
        }

        return this.httpClient.request<Token>('post',`${this.basePath}/auth/reset-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get auth bearer token
     * 
     * @param email 
     * @param password 
     * @param context 
     * @param device_token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authTokenPostForm(email: string, password: string, context: string, device_token: string, observe?: 'body', reportProgress?: boolean): Observable<Token>;
    public authTokenPostForm(email: string, password: string, context: string, device_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Token>>;
    public authTokenPostForm(email: string, password: string, context: string, device_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Token>>;
    public authTokenPostForm(email: string, password: string, context: string, device_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling authTokenPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling authTokenPost.');
        }

        if (context === null || context === undefined) {
            throw new Error('Required parameter context was null or undefined when calling authTokenPost.');
        }

        if (device_token === null || device_token === undefined) {
            throw new Error('Required parameter device_token was null or undefined when calling authTokenPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (context !== undefined) {
            formParams = formParams.append('context', <any>context) as any || formParams;
        }
        if (device_token !== undefined) {
            formParams = formParams.append('device_token', <any>device_token) as any || formParams;
        }

        return this.httpClient.request<Token>('post',`${this.basePath}/auth/token`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
