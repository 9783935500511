import {Action, createReducer, on} from '@ngrx/store';
import {SurveyInstanceHistory, TeamGlobalResult, TeamSurveyResult} from '../../../api';
import * as TeamResultsAction from './team-results.actions';

export interface TeamResultsState {
  weeklyResults: TeamSurveyResult;
  globalResults: TeamGlobalResult;
  weeklyResultsError: any;
  globalResultsError: any;
  history: Array<SurveyInstanceHistory>;
  isGlobalAvailable: boolean;
  isWeeklySurveyAvailable: boolean;
}

export const initialState: TeamResultsState = {
  weeklyResults: null,
  globalResults: null,
  weeklyResultsError: null,
  globalResultsError: null,
  history: null,
  isGlobalAvailable: true,
  isWeeklySurveyAvailable: false
};


const userReducer = createReducer(
  initialState,
  on(TeamResultsAction.initTeamResults, () => (initialState)),
  on(TeamResultsAction.saveWeeklyResults, (state, { weeklyResults, isWeeklySurveyAvailable , weeklyResultsError }) => ({ ...state, weeklyResults, isWeeklySurveyAvailable, weeklyResultsError})),
  on(TeamResultsAction.saveHistory, (state, { history }) => ({ ...state, history})),
  on(TeamResultsAction.saveGlobalResults, (state, { globalResults, isGlobalAvailable }) => ({ ...state, globalResults, isGlobalAvailable })),
);

export function reducer(state: TeamResultsState, action: Action) {
  return userReducer(state, action);
}
