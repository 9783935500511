/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An invitation to join the team
 */
export interface TeamInvitation { 
    /**
     * The unique team invitation id
     */
    id?: number;
    /**
     * The user name
     */
    name?: string;
    /**
     * The user email
     */
    email?: string;
}