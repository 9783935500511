export const environment = {
  production: true,
  preprod: false,
  basePath: 'https://server.buddyatwork.ch/api',
  version : 'v1',
  assistanceEmail: 'assistance@happinessagency.ch',

  // firebase config
  firebaseConfig: {
    apiKey: 'AIzaSyB1qtqodxDR5_qjiF2GEp2p8nk-cQrhJIE',
    authDomain: 'lilpulse.firebaseapp.com',
    projectId: 'lilpulse',
    storageBucket: 'lilpulse.appspot.com',
    messagingSenderId: '171787111713',
    appId: '1:171787111713:web:375ff9666c977c612d0260',
    measurementId: 'G-BXY10FJF8D'
  }
};
