/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Company information
 */
export interface Company { 
    /**
     * The unique company id
     */
    id?: number;
    /**
     * The company name
     */
    name?: string;
    /**
     * The company holding name (label)
     */
    label?: string;
    /**
     * Path to the logo image of the company
     */
    logo_path?: string;
    /**
     * The company language
     */
    lang?: string;
}