import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {AnswersForm, InlineResponse200, SurveyService} from '../../api';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {SURVEY_TYPE} from '../../types/surveyTypes';
import {setPulseSafetyAvailable, setPulseTeamAvailable} from '../../store/reducers/user/user.actions';
import {Store} from '@ngrx/store';

export class PostSurvey extends JustNetworkResource<string, InlineResponse200>{
  public type = '';

  constructor(private store: Store, private surveyService: SurveyService, private surveyAnswers: AnswersForm, type: SURVEY_TYPE){
    super(true, 'WEEKLY_PULSE_SENDING_THE_ANSWERS');
    this.type = type;
  }
  protected startNetworkCall(): Observable<InlineResponse200> {
    return this.surveyService.surveyTypePost(this.surveyAnswers, this.type);
  }

  protected onNetworkSuccess(result: InlineResponse200): string {
    if (this.type === SURVEY_TYPE.SAFETY_PULSE) {
      this.store.dispatch(setPulseSafetyAvailable({haveNotSeenPulseSafety: false}));
    }

    if (this.type === SURVEY_TYPE.TEAM_PULSE) {
      this.store.dispatch(setPulseTeamAvailable({haveNotSeenPulseTeam: false}));
    }
    return result.message;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.genericAsToast( false);
  }
}
