/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * TermsOfService information
 */
export interface TermsOfService { 
    /**
     * The version
     */
    version?: number;
    /**
     * Path to the file image of the company
     */
    file_path?: string;
}