/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { QuestionGlobalResult } from 'src/app/api';

@Component({
  selector: 'app-global-weather-card',
  templateUrl: './global-weather-card.component.html',
  styleUrls: ['./global-weather-card.component.scss'],
})
export class GlobalWeatherCardComponent implements OnInit {
  @Input() question: QuestionGlobalResult;
  @Input() totalDays;
  @Input() isUserGlobalWeather = false;
  public showMonthly = true;
  public teamAverage: string;
  public userAverage: string;
  public teamAverageFormated: string;
  public userAverageFormated: string;

  constructor() {}

  ngOnInit() {
    this.userAverage = this.isUserGlobalWeather ? this.question.user_average.toPrecision(2) : '';
    this.teamAverage = this.question.average.toPrecision(2);

    this.teamAverageFormated = this.question.average.toString().length === 1 ?
      this.question.average.toString() :
      this.teamAverage;

    this.userAverageFormated = this.isUserGlobalWeather && this.question.user_average.toString().length === 1 ?
      this.question.user_average.toString() :
      this.userAverage;
  }

  public showMonthlyTemplate() {
    this.showMonthly = true;
  }
  public showYearlyTemplate() {
    this.showMonthly = false;
  }
}
