/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AcceptInvitationForm } from '../model/acceptInvitationForm';
import { CustomQuestionForm } from '../model/customQuestionForm';
import { CustomQuestionResponse } from '../model/customQuestionResponse';
import { InlineResponse200 } from '../model/inlineResponse200';
import { TeamGlobalResultResponse } from '../model/teamGlobalResultResponse';
import { TeamInviteForm } from '../model/teamInviteForm';
import { TeamResponse } from '../model/teamResponse';
import { TeamSurveyBaseResult } from '../model/teamSurveyBaseResult';
import { TeamSurveyHistoryResponse } from '../model/teamSurveyHistoryResponse';
import { TeamSurveyResultResponse } from '../model/teamSurveyResultResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ManagerService {

    protected basePath = 'https://buddy-at-work.mobilethinking.ch';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Accept the user invitation to join the company
     * 
     * @param signature Unique signature of the invitation
     * @param invitation ID of the invitation
     * @param body Optional body parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public companyInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public companyInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public companyInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (signature === null || signature === undefined) {
            throw new Error('Required parameter signature was null or undefined when calling companyInvitationsInvitationPost.');
        }

        if (invitation === null || invitation === undefined) {
            throw new Error('Required parameter invitation was null or undefined when calling companyInvitationsInvitationPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (signature !== undefined && signature !== null) {
            queryParameters = queryParameters.set('signature', <any>signature);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/company-invitations/${encodeURIComponent(String(invitation))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey global results
     * 
     * @param type type of survey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyTypeGlobalResultsGet(type: string, observe?: 'body', reportProgress?: boolean): Observable<TeamGlobalResultResponse>;
    public surveyTypeGlobalResultsGet(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamGlobalResultResponse>>;
    public surveyTypeGlobalResultsGet(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamGlobalResultResponse>>;
    public surveyTypeGlobalResultsGet(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling surveyTypeGlobalResultsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamGlobalResultResponse>('get',`${this.basePath}/survey/${encodeURIComponent(String(type))}/global_results`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey history
     * 
     * @param type type of survey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyTypeHistoryGet(type: string, observe?: 'body', reportProgress?: boolean): Observable<TeamSurveyHistoryResponse>;
    public surveyTypeHistoryGet(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamSurveyHistoryResponse>>;
    public surveyTypeHistoryGet(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamSurveyHistoryResponse>>;
    public surveyTypeHistoryGet(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling surveyTypeHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamSurveyHistoryResponse>('get',`${this.basePath}/survey/${encodeURIComponent(String(type))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey weekly results
     * 
     * @param type type of survey
     * @param week Desired results week
     * @param year Desired results year
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyTypeResultsWeekYearGet(type: string, week: string, year: string, observe?: 'body', reportProgress?: boolean): Observable<TeamSurveyResultResponse>;
    public surveyTypeResultsWeekYearGet(type: string, week: string, year: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamSurveyResultResponse>>;
    public surveyTypeResultsWeekYearGet(type: string, week: string, year: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamSurveyResultResponse>>;
    public surveyTypeResultsWeekYearGet(type: string, week: string, year: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling surveyTypeResultsWeekYearGet.');
        }

        if (week === null || week === undefined) {
            throw new Error('Required parameter week was null or undefined when calling surveyTypeResultsWeekYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling surveyTypeResultsWeekYearGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamSurveyResultResponse>('get',`${this.basePath}/survey/${encodeURIComponent(String(type))}/results/${encodeURIComponent(String(week))}/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey weekly question for the manager team
     * 
     * @param type type of survey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyTypeWeeklyQuestionGet(type: string, observe?: 'body', reportProgress?: boolean): Observable<CustomQuestionResponse>;
    public surveyTypeWeeklyQuestionGet(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomQuestionResponse>>;
    public surveyTypeWeeklyQuestionGet(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomQuestionResponse>>;
    public surveyTypeWeeklyQuestionGet(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling surveyTypeWeeklyQuestionGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomQuestionResponse>('get',`${this.basePath}/survey/${encodeURIComponent(String(type))}/weekly_question`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update survey weekly question for the manager team
     * 
     * @param body Required parameters
     * @param type type of survey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyTypeWeeklyQuestionPost(body: CustomQuestionForm, type: string, observe?: 'body', reportProgress?: boolean): Observable<CustomQuestionResponse>;
    public surveyTypeWeeklyQuestionPost(body: CustomQuestionForm, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomQuestionResponse>>;
    public surveyTypeWeeklyQuestionPost(body: CustomQuestionForm, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomQuestionResponse>>;
    public surveyTypeWeeklyQuestionPost(body: CustomQuestionForm, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling surveyTypeWeeklyQuestionPost.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling surveyTypeWeeklyQuestionPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomQuestionResponse>('post',`${this.basePath}/survey/${encodeURIComponent(String(type))}/weekly_question`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add new use to the team - This will send an invitation email to the user if he doesn&#x27;t exist.
     * 
     * @param body Required user parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamAddPost(body: TeamInviteForm, observe?: 'body', reportProgress?: boolean): Observable<TeamResponse>;
    public teamAddPost(body: TeamInviteForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamResponse>>;
    public teamAddPost(body: TeamInviteForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamResponse>>;
    public teamAddPost(body: TeamInviteForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teamAddPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeamResponse>('post',`${this.basePath}/team/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user owned team information
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamGet(observe?: 'body', reportProgress?: boolean): Observable<TeamResponse>;
    public teamGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamResponse>>;
    public teamGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamResponse>>;
    public teamGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamResponse>('get',`${this.basePath}/team`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Accept the user invitation to join the team
     * 
     * @param signature Unique signature of the invitation
     * @param invitation ID of the invitation
     * @param body Optional body parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public teamInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public teamInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public teamInvitationsInvitationPost(signature: string, invitation: number, body?: AcceptInvitationForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (signature === null || signature === undefined) {
            throw new Error('Required parameter signature was null or undefined when calling teamInvitationsInvitationPost.');
        }

        if (invitation === null || invitation === undefined) {
            throw new Error('Required parameter invitation was null or undefined when calling teamInvitationsInvitationPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (signature !== undefined && signature !== null) {
            queryParameters = queryParameters.set('signature', <any>signature);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/team-invitations/${encodeURIComponent(String(invitation))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the user from the team
     * 
     * @param teamInvitation ID of the invitation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamInvitationsRemoveTeamInvitationDelete(teamInvitation: number, observe?: 'body', reportProgress?: boolean): Observable<TeamResponse>;
    public teamInvitationsRemoveTeamInvitationDelete(teamInvitation: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamResponse>>;
    public teamInvitationsRemoveTeamInvitationDelete(teamInvitation: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamResponse>>;
    public teamInvitationsRemoveTeamInvitationDelete(teamInvitation: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (teamInvitation === null || teamInvitation === undefined) {
            throw new Error('Required parameter teamInvitation was null or undefined when calling teamInvitationsRemoveTeamInvitationDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamResponse>('delete',`${this.basePath}/team-invitations/remove/${encodeURIComponent(String(teamInvitation))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the user from the team
     * 
     * @param user ID of the user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamRemoveUserDelete(user: number, observe?: 'body', reportProgress?: boolean): Observable<TeamResponse>;
    public teamRemoveUserDelete(user: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamResponse>>;
    public teamRemoveUserDelete(user: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamResponse>>;
    public teamRemoveUserDelete(user: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling teamRemoveUserDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer_token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeamResponse>('delete',`${this.basePath}/team/remove/${encodeURIComponent(String(user))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
