import { Component, Input, OnInit } from '@angular/core';
import {Answer, QuestionWithAnswers} from '../../api';

@Component({
  selector: 'app-response-range',
  templateUrl: './response-range.component.html',
  styleUrls: ['./response-range.component.scss'],
})
export class ResponseRangeComponent implements OnInit {

  @Input() question: QuestionWithAnswers;
  @Input() sortAnswersValueByMinMax: (answers: Array<Answer>, min: number, max: number) => number;
  public mouthImageUrl = 'assets/images/sad-mouth.svg';
  public questionAverage: string;
  public circlePercentage: string;

  constructor() {}

  ngOnInit() {
    const questionAverage = this.question.average.toPrecision(2);

    if (+questionAverage < 4) {
      this.mouthImageUrl = 'assets/images/sad-mouth.svg';
    } else if (+questionAverage > 3 && +questionAverage < 7) {
      this.mouthImageUrl = 'assets/images/normal-mouth.svg';
    } else {
      this.mouthImageUrl = 'assets/images/happy-mouth.svg';
    }
  }

}
