import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthService} from '../../api';
import {Token} from '../../api';
import {initAuthSession} from '../../store/reducers/auth/authSession.actions';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class Login extends JustNetworkResource<boolean, Token> {
    constructor(private store: Store<AppState>,
                private authSessionService: AuthService,
                private email: string,
                private password: string,
                private context: string,
                private deviceToken: string) {
        super(true);
    }

    protected startNetworkCall(): Observable<Token> {
        return this.authSessionService.authTokenPostForm(this.email, this.password, this.context, this.deviceToken);
    }

    protected onNetworkSuccess(authSession: Token): boolean {
        this.store.dispatch(initAuthSession({ token: authSession, context: this.context, firebaseDeviceToken: this.deviceToken}));
        return authSession.token != null;
    }

    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('LOGIN_ERROR_TOAST', false);
    }
}
