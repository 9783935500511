import {createAction, props} from '@ngrx/store';
import {SurveyInstanceHistory, TeamGlobalResult, TeamSurveyResult} from '../../../api';
export const initTeamResults = createAction('[Team Result] Init team results');

export const saveWeeklyResults = createAction('[Team Result] save team weekly results',
  props<{ weeklyResults: TeamSurveyResult; isWeeklySurveyAvailable: boolean; weeklyResultsError: any }>());

export const saveGlobalResults = createAction('[Team Result] save team global results',
  props<{ globalResults: TeamGlobalResult; isGlobalAvailable: boolean}>());

export const saveHistory = createAction('[Team Result] save history date',
  props<{ history: Array<SurveyInstanceHistory>}>());
