import {Observable} from 'rxjs';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {TOSService} from '../../api/api/tOS.service';
import {TermsOfServiceResponse} from '../../api/model/termsOfServiceResponse';

export class TermsOfServiceGet extends JustNetworkResource<TermsOfServiceResponse, TermsOfServiceResponse>{

  constructor(private termsOfServiceService: TOSService){
    super();
  }
  protected startNetworkCall(): Observable<TermsOfServiceResponse> {
    return this.termsOfServiceService.termsOfServiceGet();
  }

  protected onNetworkSuccess(response: TermsOfServiceResponse): TermsOfServiceResponse {
    return response;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return this.onOtherNetworkFailure(error);
  }

  protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.displayableAsToast('LOGIN_ASK_RESET_TOAST_ERROR', false);
  }
}
