import {Component, OnInit} from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnInit {

  public preprod: boolean;
  public currentYear = new Date().getFullYear();
  constructor() {}

  ngOnInit() {
    this.preprod = environment.preprod;
  }

}
