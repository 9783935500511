import {Component, Input, OnInit} from '@angular/core';
import {QuestionWithAnswers} from '../../api';

@Component({
  selector: 'app-response-range-line',
  templateUrl: './response-range-line.component.html',
  styleUrls: ['./response-range-line.component.scss'],
})
export class ResponseRangeLineComponent implements OnInit {
  @Input() question: QuestionWithAnswers;

  constructor() { }

  ngOnInit() {}

}
