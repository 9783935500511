import {Injectable} from '@angular/core';
import {TermsOfServiceGet} from './termsOfServiceGet';
import {TOSService} from '../../api/api/tOS.service';

@Injectable({
  providedIn: 'root'
})
export class TOSRepository {
  constructor(private termsOfServiceService: TOSService) {}

  termsOfServiceGet() {
    return new TermsOfServiceGet(this.termsOfServiceService).call();
  }
}
