/* eslint-disable max-len */
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {QuestionGlobalResult} from 'src/app/api';
import {TranslateService} from '@ngx-translate/core';
import {UserRepository} from '../../repositories/user/user.repository';
import * as moment from 'moment';
import {last} from "rxjs/operators";


@Component({
  selector: 'app-global-chart',
  templateUrl: './global-chart.component.html',
  styleUrls: ['./global-chart.component.scss'],
})
export class GlobalChartComponent implements OnInit {
  @ViewChild('chart') chart: any;

  @Input() question: QuestionGlobalResult;
  @Input() showMonthly: boolean;
  @Input() isUserCard: boolean;
  public weekSeries = [];
  public monthSeries = [];
  public xaxis = {};
  public chartOptions: Partial<any>;
  public weeklyXaxis: any;
  public monthlyXaxis: any;
  public meString: any;
  public teamString: any;

  constructor(private translateService: TranslateService, private userRepository: UserRepository) {
    this.meString = this.translateService.instant('USER_WEEKLY_ME_CURSOR');
    this.teamString = this.translateService.instant('USER_WEEKLY_TEAM_CURSOR');
  }

  ngOnInit() {
    this.userRepository.getCurrentUserLang().subscribe(lang => {
      moment.locale(lang);
    });

    const sortedWeeklyUserAnswers = this.isUserCard ? [...this.question.user_answers].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) : [];
    const sortedWeeklyTeamAnswers = [...this.question.answers].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const sortedMonthlyUserAnswers = this.isUserCard ? [...this.question.user_answers_by_months].sort((a, b) => new Date(`${a.month}/1/${a.year} GMT`).getTime() - new Date(`${b.month}/1/${b.year} GMT`).getTime()) : [];
    const sortedMonthlyTeamAnswers = [...this.question.answers_by_months].sort((a, b) => new Date(`${a.month}/1/${a.year} GMT`).getTime() - new Date(`${b.month}/1/${b.year} GMT`).getTime());

    const getWeeklyCategories = () => {
      const mappedSortedWeeklyUserAnswers = sortedWeeklyUserAnswers.map(userAnswer => userAnswer.date);
      const mappedSortedWeeklyTeamAnswers = sortedWeeklyTeamAnswers.map(teamAnswer => teamAnswer.date);
      const merged = [...new Set([...mappedSortedWeeklyUserAnswers, ...mappedSortedWeeklyTeamAnswers])].sort((a,b) => a.localeCompare(b));

      return merged.map(dateString => moment(dateString).format('MMM D'));
    };
    const weeklyCategories = getWeeklyCategories();

    const getMonthlyCategories = () => {
      const mappedSortedMonthlyUserAnswers = sortedMonthlyUserAnswers.map(userAnswer => new Date(`${userAnswer.month}/1/${userAnswer.year} GMT`).getTime());
      const mappedSortedMonthlyTeamAnswers = sortedMonthlyTeamAnswers.map(teamAnswer => new Date(`${teamAnswer.month}/1/${teamAnswer.year} GMT`).getTime());
      const merged = [...new Set([...mappedSortedMonthlyUserAnswers, ...mappedSortedMonthlyTeamAnswers])].sort((a,b) => new Date(a).getTime() - new Date(b).getTime());

      return merged.map(dateString => moment(dateString).format('MMM YYYY'));
    };
    const monthlyCategories = getMonthlyCategories();

    const userWeeklyResults = [];
    for (const category of weeklyCategories) {
      const answer = sortedWeeklyUserAnswers.find(userAnswer => category === moment(userAnswer.date).format('MMM D'));

      if (answer) {
        userWeeklyResults.push(answer.value);
      } else {
        userWeeklyResults.push(null);
      }
    }

    const teamWeeklyResults = [];
    for (const category of weeklyCategories) {
      const answer = sortedWeeklyTeamAnswers.find(userAnswer => category === moment(userAnswer.date).format('MMM D'));

      if (answer) {
        teamWeeklyResults.push(answer.value);
      } else {
        teamWeeklyResults.push(null);
      }
    }

    const userMonthlyResults = [];
    for (const category of monthlyCategories) {
      const answer = sortedMonthlyUserAnswers.find(userAnswer => category === moment(`${userAnswer.month}/1/${userAnswer.year} GMT`).format('MMM YYYY'));

      if (answer) {
        userMonthlyResults.push(answer.value);
      } else {
        userMonthlyResults.push(null);
      }
    }

    const teamMonthlyResults = [];
    for (const category of monthlyCategories) {
      const answer = sortedMonthlyTeamAnswers.find(teamAnswer => category === moment(`${teamAnswer.month}/1/${teamAnswer.year} GMT`).format('MMM YYYY'));

      if (answer) {
        teamMonthlyResults.push(answer.value);
      } else {
        teamMonthlyResults.push(null);
      }
    }

    const getWeeklyMinXAxis = () => weeklyCategories.length > 6 ? weeklyCategories.length - 6 : undefined;
    const getMonthlyMinXAxis = () => monthlyCategories.length > 5 ? monthlyCategories.length - 5 : undefined;

    this.weeklyXaxis = {
      type: 'category',
      categories: weeklyCategories,
      min: getWeeklyMinXAxis()
    };

    this.monthlyXaxis = {
      type: 'category',
      categories: monthlyCategories,
      min: getMonthlyMinXAxis()
    };

    this.weekSeries = this.isUserCard ?
      [
        {
          name: this.teamString,
          data: teamWeeklyResults
        },
        {
          name: this.meString,
          data: userWeeklyResults
        }
      ]
      :
      [
        {
          name: this.teamString,
          data: teamWeeklyResults
        }
      ];

    this.monthSeries = this.isUserCard ?
      [
        {
          name: this.teamString,
          data: teamMonthlyResults
        },
        {
          name: this.meString,
          data: userMonthlyResults
        }
      ]
      :
      [
        {
          name: this.teamString,
          data: teamMonthlyResults
        }
      ];

    this.setChartOption();
  }

  public setChartOption() {
    this.chartOptions = {
      colors: this.isUserCard ? ['#374D9B', '#F6C82D'] : ['#F6C82D'],
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '11px',
        markers: {
          width: 9,
          height: 9
        }
      },
      grid: {
        show: false,
      },
      yaxis: {
        tickAmount: 10,
        min: 0,
        max: 10,
        axisTicks: {
          show: true,
          color: '#42424239',
          offsetX: -14
        },
        labels: {
          show: true,
          align: 'center',
          style: {
            colors: ['#22264E'],
            cssClass: 'apexcharts-yaxis-label',
          },
          formatter: (val, index) => (index === 0 || index === 5 || index === 10) ? val : ''
        },
      },
      chart: {
        height: 260,
        type: 'line',
        toolbar: {
          show: true,
          autoSelected: 'pan',
          tools: {
            download: false,
            selection: false,
            pan: true,
            reset: false
          },
        },
        zoom: {
          enabled: true,
          type: 'x'
        },
        animations: {
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
        },
        events: {
          zoomed: (chartContext, { xaxis, yaxis }) => {
            console.log(xaxis)
          }
        }
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      markers: {
        size: 7,
      }
    };
  }

}
