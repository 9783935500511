import {createAction, props} from '@ngrx/store';
import {Training, User} from '../../../api';

export const setUserLang = createAction('[User] Set lang', props<{ languageCode: string }>());
export const initUser = createAction('[User] Init user', props<{ user: User }>());
export const clearUser = createAction('[User] Clear user');
export const setPulseSafetyAvailable = createAction('[User] set Safety Pulse Available', props<{haveNotSeenPulseSafety: boolean }>());
export const setPulseTeamAvailable = createAction('[User] set Team Pulse Available', props<{haveNotSeenPulseTeam: boolean }>());
export const setNewTrainingAvailable = createAction('[User] set new training Available', props<{haveNotSeenNewTraining: boolean }>());
export const setTrainingsHistory = createAction('[User] set trainings history', props<{trainingsHistory: Training[] }>());
export const setTraining = createAction('[User] set training', props<{training: Training }>());
