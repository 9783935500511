import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {AnswersForm, Survey, SurveyService} from '../../api';
import {GetSurvey} from './getSurvey';
import {Observable} from 'rxjs';
import {Resource} from '../../common/repository/resource';
import {PostSurvey} from './postSurvey';
import {selectNewPulseSafetyAvailable, selectNewPulseTeamAvailable} from '../../store/reducers/user/user.selectors';
import {setPulseTeamAvailable} from '../../store/reducers/user/user.actions';
import {SURVEY_TYPE} from '../../types/surveyTypes';

@Injectable({
  providedIn: 'root'
})
export class SurveyRepository {

  constructor(private store: Store<AppState>, private surveyService: SurveyService){}

  getSafetySurvey(): Observable<Resource<Survey>>{
    const getSurvey = new GetSurvey(this.store, this.surveyService, SURVEY_TYPE.SAFETY_PULSE);
    return getSurvey.call();
  }

  getTeamSurvey(): Observable<Resource<Survey>>{
    const getSurvey = new GetSurvey(this.store, this.surveyService, SURVEY_TYPE.TEAM_PULSE);
    return getSurvey.call();
  }

  postSafetyAnswers(answersForm: AnswersForm) {
    const postSurvey = new PostSurvey(this.store, this.surveyService, answersForm, SURVEY_TYPE.SAFETY_PULSE);
    return postSurvey.call();
  }

  postTeamAnswers(answersForm: AnswersForm) {
    const postSurvey = new PostSurvey(this.store, this.surveyService, answersForm, SURVEY_TYPE.TEAM_PULSE);
    return postSurvey.call();
  }

  getPulseSafetyAvailable(){
    return this.store.pipe(select(selectNewPulseSafetyAvailable));
  }
  getPulseTeamAvailable(){
    return this.store.pipe(select(selectNewPulseTeamAvailable));
  }

  acknowledgeSurveyAvailability(){
    return this.store.dispatch(setPulseTeamAvailable({haveNotSeenPulseTeam: false}));
  }
}
