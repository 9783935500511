import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { ManagerHomePage } from './manager-home.page';

const routes: Routes = [
      {
        path: '',
        component: ManagerHomePage
      },
      {
        path: 'weekly-setup',
        loadChildren: () => import('./weekly-setup/weekly-setup.module').then( m => m.WeeklySetupPageModule)
      },
      {
        path: 'team-results',
        loadChildren: () => import('./team-results/team-results.module').then( m => m.TeamResultsPageModule)
      },
      {
        path: 'assistance',
        loadChildren: () => import('../home/assistance/assistance.module').then( m => m.AssistancePageModule)
      },
      {
        path: 'my-people',
        loadChildren: () => import('./my-people/my-people.module').then( m => m.MyPeoplePageModule)
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagerHomePageRoutingModule {}

