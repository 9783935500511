import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManagerHomePageRoutingModule } from './manager-home-routing.module';

import { ManagerHomePage } from './manager-home.page';
import {ComponentsModule} from '../../components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManagerHomePageRoutingModule,
    ComponentsModule,
    TranslateModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 0,
      backgroundStroke: '#f00000',
      backgroundStrokeWidth: 0,
      outerStrokeGradient: true,
      outerStrokeWidth: 45,
      outerStrokeColor: '#e5698b',
      outerStrokeGradientStopColor: '#fdb607',
      innerStrokeColor: '#ffffff',
      innerStrokeWidth: 0,
      animationDuration: 900,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showBackground: false,
      clockwise: false,
      responsive: false,
      radius: 85,
    }),
  ],
  declarations: [ManagerHomePage]
})
export class ManagerHomePageModule {}
