import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-manager-menu',
  templateUrl: './manager-menu.component.html',
  styleUrls: ['./manager-menu.component.scss', '../../pages/home/home.page.scss'],
})
export class ManagerMenuComponent {

  @Output() toggleSidebarEvent = new EventEmitter();
  constructor() {}

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }
}
