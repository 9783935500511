/* eslint-disable max-len */
import { Component, OnInit, Input } from '@angular/core';
import { QuestionGlobalResult } from 'src/app/api';

@Component({
  selector: 'app-global-normal-card',
  templateUrl: './global-normal-card.component.html',
  styleUrls: ['./global-normal-card.component.scss'],
})
export class GlobalNormalCardComponent implements OnInit {
  @Input() question: QuestionGlobalResult;
  @Input() totalDays;
  @Input() isUserCard = false;
  public showMonthly = true;
  public teamAverage: string;
  public userAverage: string;
  public teamAverageFormated: string;
  public userAverageFormated: string;
  public circlePercentage: string;
  public mouthImageUrl = 'assets/images/sad-mouth.svg';

  constructor() { }

  ngOnInit() {

    this.userAverage = this.isUserCard && this.question.user_average.toPrecision(2);
    this.teamAverage = this.question.average.toPrecision(2);

    this.teamAverageFormated = this.question.average.toString().length === 1 ?
      this.question.average.toString() :
      this.teamAverage;

    this.userAverageFormated = this.isUserCard && this.question.user_average.toString().length === 1 ?
      this.question.user_average.toString() :
      this.userAverage;

    this.circlePercentage = this.isUserCard ?
      this.userAverage === '10' ? '100' : this.userAverage.split('.').join('')
      :
      this.teamAverage === '10' ? '100' : this.teamAverage.split('.').join('');

    if (this.isUserCard) {
      if (+this.userAverage < 4) {
        this.mouthImageUrl = 'assets/images/sad-mouth.svg';
      } else if (+this.userAverage > 3 && +this.userAverage < 7) {
        this.mouthImageUrl = 'assets/images/normal-mouth.svg';
      } else {
        this.mouthImageUrl = 'assets/images/happy-mouth.svg';
      }
    } else {
      if (+this.teamAverage < 4) {
        this.mouthImageUrl = 'assets/images/sad-mouth.svg';
      } else if (+this.teamAverage > 3 && +this.teamAverage < 7) {
        this.mouthImageUrl = 'assets/images/normal-mouth.svg';
      } else {
        this.mouthImageUrl = 'assets/images/happy-mouth.svg';
      }
    }
  }

  public showMonthlyTemplate() {
    this.showMonthly = true;
  }
  public showYearlyTemplate() {
    this.showMonthly = false;
  }

}
