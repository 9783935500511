import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppHeaderComponent} from './app-header/app-header.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NgApexchartsModule} from 'ng-apexcharts';
import {UiStateManagementModule} from '../common/ui/state-management/ui.state.management.module';
import {WeeklyResultsComponent} from './weekly-results/weekly-results.component';
import {GlobalResultsComponent} from './global-results/global-results.component';
import {CopyrightComponent} from './copyright/copyright.component';
import {CloudTitleComponent} from './cloud-title/cloud-title.component';
import {YellowPinkSeparatorComponent} from './yellow-pink-separator/yellow-pink-separator.component';
import {ButtonWithBorderComponent} from './button-with-border/button-with-border.component';
import {PageTitleComponent} from './page-title/page-title.component';
import {QuestionComponent} from './question/question.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordionComponent} from './accordion/accordion.component';
import {ResponseTextComponent} from './response-text/response-text.component';
import { ValidationModalComponent } from './validation-modal/validation-modal.component';
import { ResponseRangeComponent } from './response-range/response-range.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {ResponseRangeLineComponent} from './response-range-line/response-range-line.component';
import {ResponseTextUserComponent} from './response-text-user/response-text-user.component';
import {ResponseRangeUserComponent} from './response-range-user/response-range-user.component';
import { GlobalWeatherCardComponent } from './global-weather-card/global-weather-card.component';
import { GlobalNormalCardComponent } from './global-normal-card/global-normal-card.component';
import { GlobalChartComponent } from './global-chart/global-chart.component';
import {NoResultComponent} from './no-result/no-result.component';
import {YellowTitleContainerComponent} from './yellow-title-container/yellow-title-container.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {UserMenuComponent} from './user-menu/user-menu.component';
import {ShowHidePasswordComponent} from './show-hide-password/show-hide-password.component';
import {SafetyPulseComponent} from './safety-pulse/safety-pulse.component';
import {TosModalComponent} from './tos-modal/tos-modal.component';
import {SafePipe} from '../pipes/safe.pipe';
import {ManagerMenuComponent} from './manager-menu/manager-menu.component';




// every component we need to export will be declared here

@NgModule({
  declarations: [AppHeaderComponent,
    ManagerMenuComponent,
    WeeklyResultsComponent,
    GlobalResultsComponent,
    CopyrightComponent,
    CloudTitleComponent,
    YellowPinkSeparatorComponent,
    ButtonWithBorderComponent,
    PageTitleComponent,
    QuestionComponent,
    AccordionComponent,
    ResponseTextComponent,
    ValidationModalComponent,
    ResponseRangeComponent,
    ResponseRangeLineComponent,
    ResponseTextUserComponent,
    ResponseRangeUserComponent,
    GlobalWeatherCardComponent,
    GlobalNormalCardComponent,
    GlobalChartComponent,
    NoResultComponent,
    YellowTitleContainerComponent,
    SidebarComponent, UserMenuComponent, ShowHidePasswordComponent,
    SafetyPulseComponent,
    TosModalComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    UiStateManagementModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 0,
      backgroundStroke: '#f00000',
      backgroundStrokeWidth: 0,
      outerStrokeGradient: true,
      outerStrokeWidth: 45,
      outerStrokeColor: '#e5698b',
      outerStrokeGradientStopColor: '#fdb607',
      innerStrokeColor: '#ffffff',
      innerStrokeWidth: 0,
      animationDuration: 900,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showBackground: false,
      clockwise: false,
      responsive: false,
      radius: 85,
    }),
    NgApexchartsModule,
    FormsModule
  ],
  exports: [
    ManagerMenuComponent,
    AppHeaderComponent,
    WeeklyResultsComponent,
    GlobalResultsComponent,
    CopyrightComponent,
    CloudTitleComponent,
    YellowPinkSeparatorComponent,
    ButtonWithBorderComponent,
    PageTitleComponent,
    QuestionComponent,
    AccordionComponent,
    ResponseTextComponent,
    ValidationModalComponent,
    ResponseRangeComponent,
    ResponseRangeLineComponent,
    ResponseTextUserComponent,
    ResponseRangeUserComponent,
    GlobalWeatherCardComponent,
    GlobalNormalCardComponent,
    GlobalChartComponent,
    NoResultComponent,
    YellowTitleContainerComponent,
    SidebarComponent,
    UserMenuComponent,
    ShowHidePasswordComponent,
    SafetyPulseComponent,
    TosModalComponent,
    SafePipe
  ]
})
export class ComponentsModule { }
