/**
 * BuddyAtWork API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Survey instance history model
 */
export interface SurveyInstanceHistory { 
    /**
     * The unique id of the instance
     */
    id?: number;
    /**
     * The week of the instance
     */
    week?: number;
    /**
     * The year of the instance
     */
    year?: number;
}