import { Component, OnInit } from '@angular/core';
import {consumeOnlySuccess} from '../../common/rxjs/operators';
import {TOSRepository} from '../../repositories/tos/tos.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {TermsOfServiceResponse} from '../../api';
import {Status} from '../../common/repository/status';
import {UserRepository} from '../../repositories/user/user.repository';

@Component({
  selector: 'app-tos-modal',
  templateUrl: './tos-modal.component.html',
  styleUrls: ['./tos-modal.component.scss'],
})
export class TosModalComponent implements OnInit {
  public isVisible: boolean;
  public isAgreeingToTOS: boolean;
  public termsOfService: TermsOfServiceResponse['terms_of_service'];

  constructor(
    private termsOfServiceRepository: TOSRepository,
    private resourceStatusService: ResourceStatusService,
    private userRepository: UserRepository,
  ) {
    this.isVisible = false;
    this.isAgreeingToTOS = false;
    this.termsOfService = null;
  }

  ngOnInit() {
    setTimeout(() => {
      this.isVisible = true;
    }, 100);

    this.termsOfServiceRepository.termsOfServiceGet()
      .pipe(consumeOnlySuccess(this.resourceStatusService))
      .subscribe(async (response) => {
        this.termsOfService = response.terms_of_service;
      });
  }

  handleSubmit() {
    if (this.isAgreeingToTOS) {
      const {version} = this.termsOfService;
      this.userRepository.userAcceptTermsOfServicePost({
        version
      }).subscribe( (response) => {
        if (response.status === Status.SUCCESS) {
          this.userRepository.getUser().subscribe( (response) => {
            if (response.status === Status.SUCCESS) {
              location.reload();
            }
          });
        }
      });
    }
  }
}
