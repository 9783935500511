import {Action, createReducer, on} from '@ngrx/store';
import * as UserActions from '../user/user.actions';
import {Training, User} from '../../../api';

export interface UserState {
  user: User;
  lang: string;
  haveNotSeenPulseSafety: boolean;
  haveNotSeenPulseTeam: boolean;
  haveNotSeenNewTraining: boolean;
  trainingsHistory: Training[];
  training: Training|null;
}

export const initialState: UserState = {
  user: null,
  lang: null,
  haveNotSeenPulseSafety: false,
  haveNotSeenPulseTeam: false,
  haveNotSeenNewTraining: false,
  trainingsHistory: [],
  training: null,
};


const userReducer = createReducer(
    initialState,
    on(UserActions.setUserLang, (state, { languageCode }) => ({ ...state, lang: languageCode })),
    on(UserActions.initUser, (state, {user}) => ({...state, user})),
    on(UserActions.clearUser, (state) => deleteUser(state)),
    on(UserActions.setPulseSafetyAvailable, (state, {haveNotSeenPulseSafety}) => ({ ...state, haveNotSeenPulseSafety })),
    on(UserActions.setPulseTeamAvailable, (state, {haveNotSeenPulseTeam}) => ({ ...state, haveNotSeenPulseTeam })),
    on(UserActions.setNewTrainingAvailable, (state, {haveNotSeenNewTraining}) => ({ ...state, haveNotSeenNewTraining })),
    on(UserActions.setTrainingsHistory, (state, {trainingsHistory}) => ({ ...state, trainingsHistory })),
    on(UserActions.setTraining, (state, {training}) => ({ ...state, training })),
);

function deleteUser(state: UserState): UserState {
  // we have to keep the language in case of a logout
  return initialState;
}

export function reducer(state: UserState, action: Action) {
    return userReducer(state, action);
}
