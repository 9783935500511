import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss'],
})

export class NoResultComponent implements OnInit {
  @Input() happy: boolean;
  @Input() title: string;

  constructor() { }

  ngOnInit() {}

}
