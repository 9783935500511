import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input() question;
  @Input() i;
  @Input() first;
  @Input() last;
  @Input() totalLength;
  @Input() typeOfQuestion;
  @Input() goPrevious;
  @Input() nextQuestion;
  @Input() survey;
  @Input() submitAnswers;
  @Input() form: FormGroup;

  public rangeValue = 1;
  public faceImage = 'assets/images/sad-icon.svg';
  public charactersLength = 0;
  public colorRange = [
    '#ee8398',
    '#ef9081',
    '#f1a069',
    '#f3aa5a',
    '#f3ab58',
    '#f3ac56',
    '#eeaf5c',
    '#d5b97f',
    '#bdc4a2',
    '#a5cec4',
    '#A5D7D7',
  ];
  constructor() { }

  ngOnInit() {}

  public setFaceImage() {
    if (this.rangeValue < 4) {
      this.faceImage = 'assets/images/sad-icon.svg';
    } else if (this.rangeValue > 3 && this.rangeValue < 7) {
      this.faceImage = 'assets/images/normal-face-icon.svg';
    } else {
      this.faceImage = 'assets/images/happy-icon.svg';
    }
  }

  public isDisabled(id: string) {
    return this.form.value[id] === '' || this.form.value[id] === 0;
  }

  public setCharactersLength(event: any) {
    this.charactersLength = event.target.value.length;
  }
}
