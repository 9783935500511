import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerRepository} from '../../repositories/manager/manager.repository';
import {consume, consumeOnlySuccess} from '../../common/rxjs/operators';
import {switchMap, take} from 'rxjs/operators';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {Answer, QuestionWithAnswers, SurveyInstanceHistory, TeamGlobalResult, TeamSurveyResult} from '../../api';
import {Observable, Subscription} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-manager-home',
  templateUrl: './manager-home.page.html',
  styleUrls: ['../home/home.page.scss', './manager-home.page.scss'],
})
export class ManagerHomePage implements OnInit, OnDestroy {


  public globalTeamWeather: number;
  public totalDays: number;
  public globalAnsweringRatio: number;
  public pastWeekTeamWeather: QuestionWithAnswers;

  public globalSubscription: Subscription;
  public weeklySubscription: Subscription;
  public history$: Observable<Array<SurveyInstanceHistory>>;
  public question: QuestionWithAnswers;

  constructor(private managerRepository: ManagerRepository,
              private resourceStatusService: ResourceStatusService) {

    this.globalSubscription = Subscription.EMPTY;
    this.weeklySubscription = Subscription.EMPTY;
  }

  ngOnInit() {
    this.setGlobalData();
    this.history$ = this.managerRepository.getTeamHistory().pipe(consumeOnlySuccess(this.resourceStatusService));
    this.setWeeklyData();
  }

  ngOnDestroy(): void {
    this.globalSubscription.unsubscribe();
    this.weeklySubscription.unsubscribe();
  }

  ionViewWillLeave(){
    this.globalSubscription.unsubscribe();
    this.weeklySubscription.unsubscribe();
  }

  setGlobalData(){
    this.globalSubscription =  this.managerRepository.getTeamGlobalResults().pipe(consumeOnlySuccess(this.resourceStatusService), take(1))
      .subscribe((res: TeamGlobalResult) => {
        const startDate = new Date(res.start_date).getTime();
        const endDate = new Date(res.end_date).getTime();
        //get the number of days
        this.totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
        // get the global average
        this.globalTeamWeather = res.questions?.find(question => question.position === 0).average;
        // get the global answering ratio
        this.globalAnsweringRatio = Math.trunc(res.global_answering_ratio * 100);
        // the global weather question
        this.question = res.questions?.find(question => question.position === 0);
      });
  }

  setWeeklyData(){
    this.weeklySubscription = this.history$
      .pipe(
        take(1),
        switchMap((history: Array<SurveyInstanceHistory>) => {
          if(history.length>1){
            return this.managerRepository.getTeamWeeklyResults(history[history.length-2].week.toString(), history[history.length-2].year.toString());
          } else if (history.length === 1){
            return this.managerRepository.getTeamWeeklyResults(history[0].week.toString(), history[0].year.toString().toString());
          } else {
            return of();
          }
        })
      ).pipe(consumeOnlySuccess(this.resourceStatusService)).subscribe((res: TeamSurveyResult) => {
        this.pastWeekTeamWeather = res.questions?.find(question => question.position === 0);
      });
  }

  public sortAnswersValueByMinMax(answers: Array<Answer>, min: number, max: number){
    return answers.filter((answer) => answer.value >= min && answer.value<=max).length;
  }

}
