import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-yellow-title-container',
  templateUrl: './yellow-title-container.component.html',
  styleUrls: ['./yellow-title-container.component.scss'],
})
export class YellowTitleContainerComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit() {}

}
