import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {storageSync, StorageSyncEffects} from './common/store/capacitor';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {reducers} from './store';
import {hydratedKey} from './store/reducers/hydrate/hydrate.selectors';
import {ErrorInterceptor, TokenInterceptor} from './common/api/interceptors/token.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_BASE_HREF, DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {ApiModule, BASE_PATH} from './api';


import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {filter, switchMap, take} from 'rxjs/operators';
import {HydrateRepository} from './repositories/hydrate/hydrate.repository';
import {UserRepository} from './repositories/user/user.repository';
import {authSessionKey} from './store/reducers/auth/authSession.selectors';
import {userKey} from './store/reducers/user/user.selectors';
import {ManagerHomePageModule} from './pages/manager-home/manager-home.module';
import {UiStateManagementModule} from './common/ui/state-management/ui.state.management.module';
import {InvitationCallbackService} from './custom-api/invitation-callback.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ComponentsModule} from './components/components.module';

// Rehydrate strategy
export function onSyncError(err) {
  console.log(err);
}

// translation
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const storageSyncReducer = storageSync({
  keys: [
    authSessionKey,
    userKey
  ],
  ignoreActions: [], // Don't sync when these actions occur
  hydratedStateKey: hydratedKey, // Add this key to the state
  onSyncError      // If a sync fails
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [storageMetaReducer];

export const BASE_URL = environment.basePath + '/' + environment.version;

export const initLocale = (locale: string) => {
  switch (locale){
    case 'en' :  return registerLocaleData(localeEn, 'en');
    case 'fr' : return registerLocaleData(localeFr, 'fr');
    default : return registerLocaleData(localeEn, 'en');
  }
};

export function initApp(hydrateRepository: HydrateRepository, userRepository: UserRepository, translate: TranslateService) {
  // the fallback lang is always english
  translate.setDefaultLang('en');
  registerLocaleData(localeEn, 'en');

  return () => new Promise<any>((resolve: any) => {
    hydrateRepository.getHydrateStatus().pipe(
      filter((hydrate: boolean) => hydrate),
      switchMap(() => userRepository.getCurrentUserLang()),
      take(1),
      switchMap((currentLang: string) => {
        if (currentLang == null) {
          let lang = translate.getBrowserLang();
          if (lang === undefined) {
            lang = 'en';
          }
          userRepository.setCurrentUserLang(lang);
          initLocale(lang);
          return translate.use(lang);
        }
        else {
          initLocale(currentLang);
          return translate.use(currentLang);
        }
      }),
    ).subscribe((variable) => {
      resolve(null);
    }, err => {
      console.error(`Problem with language initialization.'`);
      console.error(JSON.stringify(err));
    });
  });
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        NgApexchartsModule,
        IonicModule.forRoot( {
          mode: 'ios',
          scrollAssist: false,
          scrollPadding: false
        }),
        AppRoutingModule,
        ManagerHomePageModule,
        ApiModule,
        ComponentsModule,
        StoreModule.forRoot(
            reducers,
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictStateSerializability: true,
                    strictActionSerializability: false,
                },
                metaReducers,
            }
        ),
        HttpClientModule, // <--- add this
        TranslateModule.forRoot({ // <--- add this
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        EffectsModule.forRoot([StorageSyncEffects]),
        // To see the redux dev tool in browser
        // must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        UiStateManagementModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
  providers: [
    DatePipe,
    {provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [HydrateRepository, UserRepository, TranslateService]},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: APP_BASE_HREF, useValue : '/'},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: BASE_PATH, useValue: BASE_URL},
    InvitationCallbackService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
