import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss', './hamburger.scss'],
})
export class AppHeaderComponent implements OnInit {
  public isSidebarOpen = false;
  public router;

  constructor(router: Router) {
    this.router = router;
  }

  ngOnInit(){}

  public toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
}
