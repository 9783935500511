import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthService, Token} from '../../api';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {initAuthSession} from '../../store/reducers/auth/authSession.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class ResetPasswordCallback extends JustNetworkResource<boolean, Token> {
  constructor(private store: Store<AppState>,
              private authService: AuthService,
              private token: string,
              private email: string,
              private password: string,
              private passwordConfirmation: string,
              private context: string,)
  {
    super(true);
  }

  protected startNetworkCall(): Observable<Token> {
    return this.authService.authResetPasswordPostForm(
      this.token, this.email, this.context, this.password,
      this.passwordConfirmation );
  }

  protected onNetworkSuccess(authSession: Token): boolean {
    this.store.dispatch(initAuthSession({ token: authSession, context: this.context, firebaseDeviceToken: null}));
    return authSession.token != null;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return this.onOtherNetworkFailure(error);
  }

  protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.displayableAsToast('RESET_PASSWORD_TOAST_ERROR', false);
  }
}
