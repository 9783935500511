import { Component, OnInit, Input } from '@angular/core';
import {QuestionWithAnswers} from '../../api';

@Component({
  selector: 'app-response-text-user',
  templateUrl: './response-text-user.component.html',
  styleUrls: ['./response-text-user.component.scss'],
})
export class ResponseTextUserComponent implements OnInit {
@Input() question: QuestionWithAnswers;
  constructor() {}

  ngOnInit() {}
}
