import {ManagerService, TeamSurveyBaseResult, TeamSurveyResult, TeamSurveyResultResponse} from '../../api';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {NetworkBoundResource, ResourceFetchOptions} from '../../common/repository/networkBoundResource';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';
import {saveWeeklyResults} from '../../store/reducers/team-results/team-results.actions';
import {selectWeeklyResults} from '../../store/reducers/team-results/team-results.selectors';
import {HttpErrorResponse} from '@angular/common/http';
import {SURVEY_TYPE} from '../../types/surveyTypes';

export class GetWeeklyResultsWeekYear extends NetworkBoundResource<any, TeamSurveyResultResponse>{
  public type = '';

  constructor(private store: Store, private managerService: ManagerService, private week: string, private year: string, type: SURVEY_TYPE){
    super();
    this.type = type;
  }

  protected loadFromDatabase(): Observable<TeamSurveyResult> {
    return this.store.pipe(select(selectWeeklyResults));
  }

  protected saveNetworkResult(dataFromNetwork: TeamSurveyResultResponse) {
    this.store.dispatch(saveWeeklyResults({
      weeklyResults: dataFromNetwork.weekly_result, isWeeklySurveyAvailable: true, weeklyResultsError: null
    }));
    return dataFromNetwork;
  }

  protected shouldFetch(dataFromDatabase: TeamSurveyResult): ResourceFetchOptions {
    return {
      shouldErrorIfNoNetwork: dataFromDatabase == null,
      shouldFetch: true,
      rateLimiterOptions: {
        maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
        resourceId: this.constructor.name
      }
    };
  }

  protected startNetworkCall(): Observable<TeamSurveyResultResponse> {
    return this.managerService.surveyTypeResultsWeekYearGet(this.type,this.week, this.year);
  }

  protected handleNetworkFailure(error: HttpErrorResponse): any {
    if(error.status === 300) {
      const errorObject = error.error as {weekly_result: TeamSurveyBaseResult; errors: any};
      this.store.dispatch(saveWeeklyResults(
        {weeklyResults: errorObject.weekly_result, isWeeklySurveyAvailable: false, weeklyResultsError: errorObject.errors}));
    }
    return super.handleNetworkFailure(error.error);
  }
}
