import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthService, InlineResponse200} from '../../api';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {setFirebaseAuthToken} from '../../store/reducers/auth/authSession.actions';

export class PostDeviceToken extends JustNetworkResource<string, InlineResponse200> {
    constructor(private store: Store<AppState>,
                private authSessionService: AuthService,
                private deviceToken: string,
                private context: string) {
        super(true);
    }

    protected startNetworkCall(): Observable<InlineResponse200> {
      this.store.dispatch(setFirebaseAuthToken({firebaseDeviceToken: this.deviceToken, context: this.context}));
      return this.authSessionService.authDeviceTokenPost({context: this.context, device_token: this.deviceToken});
    }

    protected onNetworkSuccess(response: InlineResponse200): string {
      //this.store.dispatch(setFirebaseAuthToken({firebaseDeviceToken: this.deviceToken, context: this.context}));
        return response.message;
    }
}
