import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cloud-title',
  templateUrl: './cloud-title.component.html',
  styleUrls: ['./cloud-title.component.scss'],
})
export class CloudTitleComponent implements OnInit {
  @Input() title = '';
  constructor() { }

  ngOnInit() {}
}
