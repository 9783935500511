import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserRepository} from '../repositories/user/user.repository';
import {AuthSessionRepository} from '../repositories/auth/authSession.repository';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {User} from '../api';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  constructor(private router: Router,
              private userRepository: UserRepository,
              private authSessionRepository: AuthSessionRepository) {
  }

  // this guard is to redirect the  redirect the manager to its page
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authSessionRepository.getAccessToken().pipe(
      switchMap((token?: string) => {
        if(token == null) {
          return this.router.navigateByUrl('/login', { replaceUrl: true });
        }
        else {
          return this.userRepository.getCurrentUser();
        }}),
      switchMap((user?: User ) => {
          // we need a tye enum here
          if(user.role === 'manager') {
            return this.router.navigateByUrl('/manager', { replaceUrl: true });
          }
          else {return of(true);}
      })
    );
  }
}
