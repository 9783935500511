import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {Observable} from 'rxjs';
import {selectHydrateState} from '../../store/reducers/hydrate/hydrate.selectors';

@Injectable({
    providedIn: 'root'
})
export class HydrateRepository {

    constructor(private store: Store<AppState>) {}

    public getHydrateStatus(): Observable<boolean> {
        return this.store.pipe(select(selectHydrateState));
    }
}
