import {Token} from '../../../api';
import * as AuthActions from './authSession.actions';
import {Action, createReducer, on} from '@ngrx/store';

export interface AuthSessionState {
  token: string;
  context: string;
  firebaseDeviceToken?: string;
}

export const initialState: AuthSessionState = {
  token: null,
  context: null,
  firebaseDeviceToken: null
};

const authSessionReducer = createReducer(
  initialState,
  on(AuthActions.initAuthSession, (state, { token, context, firebaseDeviceToken }) => saveAuthSession(state, token, context, firebaseDeviceToken)),
  on(AuthActions.clearAuthSession, () => deleteAuthSession()),
  on(AuthActions.setFirebaseAuthToken, (state, { firebaseDeviceToken, context }) => ({...state, firebaseDeviceToken, context}))
);

function saveAuthSession(state: AuthSessionState, sessionToken: Token, context: string, firebaseDeviceToken: string): AuthSessionState {
  return {...state ,token: sessionToken.token, context, firebaseDeviceToken };
}

function deleteAuthSession(): AuthSessionState {
  return initialState;
}

export function reducer(state: AuthSessionState | undefined, action: Action) {
  return authSessionReducer(state, action);
}
