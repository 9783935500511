import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {Observable} from 'rxjs';
import {setUserLang} from '../../store/reducers/user/user.actions';
import {
  selectUserLang,
  selectUser,
  selectUserRole,
  selectUserTrainingsHistory, selectUserTraining, selectIsTrainingAvailable
} from '../../store/reducers/user/user.selectors';
import {GetUser} from './getUser';
import {TermsOfServiceForm, Training, User, UserService} from '../../api';
import {initLocale} from '../../app.module';
import {TranslateService} from '@ngx-translate/core';
import {CancelSafetyPulse} from './cancelSafetyPulse';
import {UserAcceptTermsOfServicePost} from './userAcceptTermsOfServicePost';
import {TrainingsHistoryGet} from './trainingsHistoryGet';
import {TrainingGet} from './trainingGet';

@Injectable({
  providedIn: 'root'
})
export class UserRepository {

  constructor(private store: Store<AppState>, private userService: UserService, private translateService: TranslateService){}

  getCurrentUserLang(): Observable<string> {
    return this.store.pipe(select(selectUserLang));
  }

  getCurrentUser(): Observable<User> {
    return this.store.pipe(select(selectUser));
  }

  getUserRole(): Observable<string> {
    return this.store.pipe(select(selectUserRole));
  }

  getUserTrainingsHistory(): Observable<Training[]> {
    return this.store.pipe(select(selectUserTrainingsHistory));
  }

  getUserTraining(): Observable<Training> {
    return this.store.pipe(select(selectUserTraining));
  }

  setCurrentUserLang(languageCode: string) {
    this.translateService.use(languageCode);
    initLocale(languageCode);
    return this.store.dispatch(setUserLang( { languageCode }));
  }

  getUser(){
    const getUser = new GetUser(this.store, this.userService);
    return getUser.call();
  }

  trainingGet(){
    return new TrainingGet(this.store, this.userService).call();
  }

  trainingsHistoryGet(){
    return new TrainingsHistoryGet(this.store, this.userService).call();
  }

  getIsTrainingAvailable(){
    return this.store.pipe(select(selectIsTrainingAvailable));
  }

  cancelSafetyPulse() {
    const cancelSafetyPulse = new CancelSafetyPulse(this.userService);
    return cancelSafetyPulse.call();
  }

  userAcceptTermsOfServicePost(termsOfServiceForm: TermsOfServiceForm) {
    return new UserAcceptTermsOfServicePost(this.userService, termsOfServiceForm).call();
  }
}
