import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {RouteGuard} from './guards/route.guard';
import {ManagerGuard} from './guards/manager.guard';
import {NotFoundPage} from './pages/not-found/not-found.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [RouteGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'manager',
    loadChildren: () => import('./pages/manager-home/manager-home.module').then( m => m.ManagerHomePageModule),
    canActivate: [ManagerGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule),
  },
  {
    path: 'invitation',
    loadChildren: () => import('./pages/invitation/invitation.module').then(m => m.InvitationPageModule)
  },
  {
    path: 'reset_password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: '**',
    component: NotFoundPage
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        preloadingStrategy: PreloadAllModules,
        useHash: true
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
