/* eslint-disable max-len */
import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Question, TeamGlobalResult} from '../../api';
import {ManagerRepository} from '../../repositories/manager/manager.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {consume} from '../../common/rxjs/operators';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'global-results',
  templateUrl: './global-results.component.html',
  styleUrls: ['./global-results.component.scss'],
})
export class GlobalResultsComponent implements OnInit {
  @Input() globalResultsNormalCardTemplate: TemplateRef<any>;
  @Input() globalResultsWeatherCardTemplate: TemplateRef<any>;

  public globalResults$: Observable<TeamGlobalResult>;
  public isGlobalResultsAvailable$: Observable<boolean>;
  public showNoResultsPage: boolean;
  public showMonthlyOrYearly: any;
  public totalDays: number;

  constructor(private managerRepository: ManagerRepository, private resourceStatusService: ResourceStatusService) {

  }

  ngOnInit() {
    this.initializeClassVariables();
    this.getGlobalResult();
    this.isGlobalResultsAvailable$ = this.managerRepository.getIsSurveyAvailable();
  }

  public getGlobalResult(){
    this.showMonthlyOrYearly = {};
    this.globalResults$ = this.managerRepository.getTeamGlobalResults().pipe(
      consume(this.resourceStatusService),
      tap((res) => {
        const startDate  = new Date(res.start_date).getTime();
        const endDate = new Date(res.end_date).getTime();
        //get the number of days
        this.totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        // const weatherQuestion = res.questions[0];
        // const mappedAnswers = realData ? weatherQuestion.answers.map(answer => +answer.value) : [3,4,5,6,3,2,9];
        // const mappedUserAnswers = realData && weatherQuestion.user_answers ? weatherQuestion.user_answers.map(answer => +answer.value) : [4,5,6,8,1,5,3];
        // const mappedAnswersByMonths = realData ? weatherQuestion.answers_by_months.map(answer => +answer.value) : [3,4,5,6,3,2,9];
        // const mappedUserAnswersByMonths = realData && weatherQuestion.user_answers ? weatherQuestion.user_answers_by_months.map(answer => +answer.value) : [1,2,8,3,2,3,8];


        const monthSeries = [];

        const yearSeries = [];

        const weeks = [];

        res.questions.forEach(question => {
          const mappedAnswers = question.user_answers ? question.user_answers.map(answer => +answer.value) : question.answers.map(answer => +answer.value);
          const mappedAnswersByMonth = question.user_answers ? question.user_answers_by_months.map(answer => +answer.value) : question.answers_by_months.map(answer => +answer.value);
          const mappedWeeks = question.user_answers ? question.user_answers.map(answer => `Week ${answer.week}`) : question.answers.map(answer => `Week ${answer.week}`);

          monthSeries.push(mappedAnswers);
          yearSeries.push(mappedAnswersByMonth);
          weeks.push(mappedWeeks);
        });

        return res;
      })
    );
  }

  public showMonthlyTemplate(index: number){
    this.showMonthlyOrYearly[index] = true;
  }

  public showYearlyTemplate(index: number){
    this.showMonthlyOrYearly[index] = false;
  }

  public sortQuestionByPosition(questions?: Array<Question>): Array<Question> {
    const sortedQuestion = [...questions];
    return sortedQuestion.sort((q1, q2) =>  q1.position - q2.position);
  }

  private initializeClassVariables(){
    this.showNoResultsPage = false;
    this.showMonthlyOrYearly = true;
    this.totalDays = 0;
  }

}
