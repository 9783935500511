import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {AuthSessionRepository} from '../repositories/auth/authSession.repository';
import {UserRepository} from '../repositories/user/user.repository';
import {User} from '../api';

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard implements CanActivate {

  constructor(private router: Router,
              private userRepository: UserRepository,
              private authSessionRepository: AuthSessionRepository) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authSessionRepository.getAccessToken().pipe(
      switchMap((token?: string) => {
        if (token == null) {
          return of(this.router.createUrlTree(['login']));
        } else {
          return this.userRepository.getCurrentUser();
        }
      }),
      switchMap((user: User) => {
          // need a type enum for the users role
          if(user.role === 'manager') {
            return of(true);
          }
          else {
            return of(this.router.createUrlTree(['/']));
          }
      })
    );
  }
}
