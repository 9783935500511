import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap, take} from 'rxjs/operators';
import {AuthSessionRepository} from '../../../repositories/auth/authSession.repository';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private authSessionRepository: AuthSessionRepository;

  private authSessionRequestInProgress: boolean;

  constructor(private injector: Injector) {
    this.authSessionRequestInProgress = false;
  }

  private static getRequestWithAuthorizationHeaders(request: HttpRequest<any>,
                                                    accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken} `
      }
    });
  }

  private static performRequestWithAuthorization(request: HttpRequest<any>,
                                                 next: HttpHandler,
                                                 accessToken: string): Observable<HttpEvent<any>> {
    const authorizedRequest = TokenInterceptor.getRequestWithAuthorizationHeaders(request, accessToken);
    return next.handle(authorizedRequest);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // THIS IS DOING CIRCULAR DI to check
    this.authSessionRepository = this.injector.get(AuthSessionRepository);

    // if the request is to the auth endpoint just let it pass as is
    // if the request is to local resources just let it pass as is
    if (request.url.includes('/auth/token') || !request.url.includes('http') || !request.url.includes('https')) {
      return next.handle(request);
    }

    return this.authSessionRepository.getAccessToken().pipe(
      take(1),
      switchMap((token: string) => TokenInterceptor.performRequestWithAuthorization(request, next, token))
    );

  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private authSessionRepository: AuthSessionRepository;

  constructor(private injector: Injector) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authSessionRepository = this.injector.get(AuthSessionRepository);

    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        // any error different than 401 not authorized we just throw below
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // we are not authorized to do the request
          // if the request is to local resources just throw the error
          // it should not happen but to be safe
          if (!request.url.includes('http') || !request.url.includes('https')) {
            return throwError(error);
          }
          if (!request.url.includes('/auth/device_token')) {
            this.authSessionRepository.clearAuthSession();
          }
        }
        else {
          return throwError(error);
        }
      }));
  }
}
