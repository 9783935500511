import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../api';
import {UserRepository} from '../../repositories/user/user.repository';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss', '../../pages/home/home.page.scss'],
})
export class SidebarComponent implements OnInit {

  @Input() isSidebarOpen: boolean;
  @Output() toggleSidebarEvent = new EventEmitter();

  public user$: Observable<User>;
  constructor(
    private userRepository: UserRepository,
  ) {}

  ngOnInit() {
    this.user$ = this.userRepository.getCurrentUser();
  }

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

}
