import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthService, InlineResponse200, LogoutForm} from '../../api';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {clearAuthSession} from '../../store/reducers/auth/authSession.actions';
import {clearUser} from '../../store/reducers/user/user.actions';

export class Logout extends JustNetworkResource<string, InlineResponse200> {
  constructor(private store: Store<AppState>,
              private authSessionService: AuthService,
              private context: string) {
    super(true);
  }

  protected startNetworkCall(): Observable<InlineResponse200> {
    const logoutForm: LogoutForm = {context: this.context};
    return this.authSessionService.authLogoutPost(logoutForm);
  }

  protected onNetworkSuccess(logoutResponse: InlineResponse200): string {
    this.store.dispatch(clearAuthSession());
    this.store.dispatch(clearUser());
    return logoutResponse.message;
  }

  protected handleNetworkFailure(error: HttpErrorResponse): Error {
    return this.onOtherNetworkFailure(error);
  }

  protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
    return UserFriendlyError.displayableAsToast('LOGOUT_ERROR_TOAST', false);
  }
}
