import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SurveyRepository} from '../../repositories/survey/survey.repository';
import {Observable, Subscription} from 'rxjs';
import {UserRepository} from '../../repositories/user/user.repository';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss', '../../pages/home/home.page.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {

  @Output() toggleSidebarEvent = new EventEmitter();
  public teamSurveySubscription: Subscription;
  public safetySurveySubscription: Subscription;
  public trainingSubscription: Subscription;

  public isNewTeamSurveyAvailable$: Observable<boolean>;
  public isNewSafetySurveyAvailable$: Observable<boolean>;
  public isNewTrainingAvailable$: Observable<boolean>;

  constructor(
    private surveyRepository: SurveyRepository,
    private userRepository: UserRepository
  ) {
    this.teamSurveySubscription = Subscription.EMPTY;
    this.safetySurveySubscription = Subscription.EMPTY;
    this.trainingSubscription = Subscription.EMPTY;
  }

  ngOnInit() {
    this.teamSurveySubscription = this.surveyRepository.getTeamSurvey().subscribe();
    this.safetySurveySubscription = this.surveyRepository.getSafetySurvey().subscribe();
    this.trainingSubscription = this.userRepository.trainingGet().subscribe();

    this.isNewTeamSurveyAvailable$ = this.surveyRepository.getPulseTeamAvailable();
    this.isNewSafetySurveyAvailable$ = this.surveyRepository.getPulseSafetyAvailable();
    this.isNewTrainingAvailable$ = this.userRepository.getIsTrainingAvailable();
  }

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

  ngOnDestroy(): void {
    this.teamSurveySubscription.unsubscribe();
    this.safetySurveySubscription.unsubscribe();
    this.trainingSubscription.unsubscribe();
  }

}
