import {ActionReducerMap} from '@ngrx/store';
import {reducer as hydratedReducer} from './reducers/hydrate/hydrate.reducer';
import {reducer as userReducer, UserState} from './reducers/user/user.reducer';
import {AuthSessionState, reducer as authSessionReducer} from './reducers/auth/authSession.reducer';
import {reducer as teamReducer, TeamState} from './reducers/team/team.reducer';
import {reducer as teamResultsReducer, TeamResultsState} from './reducers/team-results/team-results.reducer';

export interface AppState {
  hydrated: boolean;
  userState: UserState;
  authSession: AuthSessionState;
  teamState: TeamState;
  teamResultsState: TeamResultsState;
}

export const reducers: ActionReducerMap<AppState> = {
  hydrated: hydratedReducer,
  userState: userReducer,
  authSession: authSessionReducer,
  teamState: teamReducer,
  teamResultsState: teamResultsReducer
};
